import React from 'react';


const Textslide = () => {
	return (
		<div className="text-container">
			<div className="subtext-div">
				<div className="a1-div">
					<p>INTERNATIONAL PROJECTS</p>
				</div>
				<div className="a2-div">
					<p>
					Kalyani Homes has been the most trusted and has gained reputation worldwide.<br />
					Kalyani Homes is among India's leading real estate that has made a significant remark in exclusive locations.
					</p>
				</div>
				<div className="a3-div">
					<p>OUR DEVELOPMENTS ALL OVER THE WORLD</p>
				</div>
				<div className="a4-div">
					<p>
						Check out our properties in different destinations around the world.
					</p>
				</div>
			</div>
		</div>
	);
};

export default Textslide;
