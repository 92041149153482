const singlesslideapi = [
	{
		id: 1,
		src: 'https://rajasthanstudio.com/wp-content/uploads/2020/10/9-Bizarre-And-Astonishing-Buildings-Of-Rajasthan-2.jpg',
	},
	{
		id: 2,
		src: 'https://img.freepik.com/premium-photo/geometric-facades-residential-building_294094-27.jpg',
	},
	{
		id: 3,
		src: 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/800px-Image_created_with_a_mobile_phone.png',
	},
	// {
	// 	titile: '--Choose House--',
	// 	house: [
	// 		'Lisbon House',
	// 		'Frankfurt House',
	// 		'Amsterdam House',
	// 		'Denver House',
	// 		'Swansea House',
	// 		'Sofia House',
	// 		'Turin House',
	// 	],
	// },
];

export default singlesslideapi;
