import React from 'react'
import { useState } from 'react';
import { FaVideo, FaRegComments } from 'react-icons/fa';
import {
	FaFacebook,
	FaInstagram,
	FaWhatsapp,
	FaGooglePlus,
} from 'react-icons/fa';

const Blogpopup = ({setShow}) => {
	const closeShow = () => {
		setShow(false);
	};

	const [form, setForm] =useState({
		Comment:' ',
		Name:' ',
		Gmail: ' ', 
		Number:' '
	});

	const[data,setData]=useState([])
	// console.log(form);
	const changeHnadler =(e)=>{
		setForm({...form,[e.target.name]:e.target.value})
	}
console.log(data)

	return (
		<div className="Blogpopup-main-container">
			<div className="blogpop-section-container">
				<button className="close-wtp-form" onClick={closeShow}>
					X
				</button>
				<div className="blogpop-one-div-popup">
					<div>
						<div className="blog-date-div-container">
							<div className="date-tag-div">
								<h4>APR</h4>
								<h4>19</h4>
							</div>
							<div className="name-news-head-div">
								<div className="name-newslatter-tag-div-bar">
									<div>
										<h2>Kalyani Homes "Roundup"!</h2>
									</div>
									<div className="ultag-author-div-bar">
										<ul>
											<li>Author: Kalyani</li>
											<li>
												<button className="button-roundup-class">
													<FaVideo className="videoicon" />
													&nbsp;&nbsp;Categories: News, Roundup
												</button>
											</li>
										</ul>
									</div>
								</div>
								<div className="comments-div-bar">
									<FaRegComments className="commentsicon" />
									&nbsp;&nbsp;(<span>{data.length}</span>)Comments
								</div>
							</div>
						</div>
						<div className="iframe-video-div-class-main">
							<div className="iframe-video-div-class">
								<img
									src="https://images.unsplash.com/photo-1600585154340-be6161a56a0c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8aG9tZXN8ZW58MHx8MHx8"
									alt=""
								/>
							</div>
							<div className="text-div-more-container">
								<p>May new home sales gain 2.2% from April</p>
								<p>
									Sales of new single-family houses in May 2015 were at a
									seasonally adjusted annual rate of 546,000, which is up 2.2%
									from April, according to estimates released jointly today by
									the U.S. Census Bureau and the Department of Housing and Urban
									Development. — From Housing Wire
								</p>
								<p>3 ways to tame student loan debt and afford a mortgage</p>
								<p>
									It’s no secret that student loans can make buying a home a
									challenge. But what exactly is the problem, and how can buyers
									overcome it? The problem is that student loans can be included
									in the buyer’s debt-to-income ratio, or DTI. — From Bankrate
								</p>
								<p>May new home sales gain 2.2% from April</p>
								<p>
									Sales of new single-family houses in May 2015 were at a
									seasonally adjusted annual rate of 546,000, which is up 2.2%
									from April, according to estimates released jointly today by
									the U.S. Census Bureau and the Department of Housing and Urban
									Development. — From Housing Wire
								</p>
								<p>3 ways to tame student loan debt and afford a mortgage</p>
								<p>
									It’s no secret that student loans can make buying a home a
									challenge. But what exactly is the problem, and how can buyers
									overcome it? The problem is that student loans can be included
									in the buyer’s debt-to-income ratio, or DTI. — From Bankrate
								</p>
								<p>May new home sales gain 2.2% from April</p>
								<p>
									Sales of new single-family houses in May 2015 were at a
									seasonally adjusted annual rate of 546,000, which is up 2.2%
									from April, according to estimates released jointly today by
									the U.S. Census Bureau and the Department of Housing and Urban
									Development. — From Housing Wire
								</p>
								<p>3 ways to tame student loan debt and afford a mortgage</p>
								<p>
									It’s no secret that student loans can make buying a home a
									challenge. But what exactly is the problem, and how can buyers
									overcome it? The problem is that student loans can be included
									in the buyer’s debt-to-income ratio, or DTI. — From Bankrate
								</p>
							</div>
							<div className="image-container-div-bar">
								<div className="image-conatiner-section">
									<ul>
										<li>
											<img
												src="https://img.freepik.com/free-vector/city-skyline-concept-illustration_114360-8923.jpg"
												alt=""
											/>
										</li>
										<li>
											<img
												src="https://img.freepik.com/free-vector/building-concept-illustration_114360-4469.jpg"
												alt=""
											/>
										</li>
										<li>
											<img
												src="https://thefinancialexpress.com.bd/uploads/1644201480.jpg"
												alt=""
											/>
										</li>
										<li>
											<img
												src="https://previews.123rf.com/images/microone/microone1908/microone190800354/129242995-flat-downtown-skyscrapers-exterior-of-modern-city-buildings-residential-and-business-office-houses-c.jpg"
												alt=""
											/>
										</li>
										{/* <li>
											<img
												src="https://previews.123rf.com/images/microone/microone1908/microone190800354/129242995-flat-downtown-skyscrapers-exterior-of-modern-city-buildings-residential-and-business-office-houses-c.jpg"
												alt=""
											/>
										</li> */}
									</ul>
								</div>
							</div>
							<div className="comment-box-container">
								<div className="comment-box-section">
									<div className="text-info-social">
										<div>
											<h3>Leave a Reply</h3>
										</div>
										<div>
											<p>Connect with:</p>
										</div>
										<div className="social-icon-box">
											<div className="icon-media-div-bar-comment">
												<ul>
													<li>
														<button className="social-icon">
															<FaFacebook className="fbicon" />
														</button>
													</li>
													<li>
														<button className="social-icon">
															<FaInstagram className="insticon" />
														</button>
													</li>
													<li>
														<button className="social-icon">
															<FaWhatsapp className="whaticon" />
														</button>
													</li>
													<li>
														<button className="social-icon">
															<FaGooglePlus className="googleicon" />
														</button>
													</li>
												</ul>
											</div>
										</div>
										<div>
											<p>COMMENT</p>
										</div>
										<div className="form-input-action-div">
											<div action="">
												<div className="text-input-div">
													<label htmlFor="COMMENT">COMMENT</label>
													<textarea
														className="textarea-box"
														value={form.Comment}
														onChange={(e) => {
															changeHnadler(e);
														}}
														name="Comment"
													>
														say somethings.........?
													</textarea>
												</div>
												<div className="lable-names-div">
													<ul>
														<li>
															<div className="form-input">
																<label htmlFor="">Name </label>
																<input
																	type="text"
																	placeholder="Name"
																	value={form.Name}
																	name="Name"
																	onChange={(e) => {
																		changeHnadler(e);
																	}}
																/>
															</div>
														</li>
														<li>
															<div className="form-input">
																<label htmlFor="">Email </label>
																<input
																	type="email"
																	placeholder="Gmail"
																	value={form.Gmail}
																	name="Gmail"
																	onChange={(e) => {
																		changeHnadler(e);
																	}}
																/>
															</div>
														</li>
														<li>
															<div className="form-input">
																<label htmlFor="">Mobile Number </label>
																<input
																	type="number"
																	placeholder="Number"
																	value={form.Number}
																	name="Number"
																	onChange={(e) => {
																		changeHnadler(e);
																	}}
																/>
															</div>
														</li>
													</ul>
												</div>
												<div className="post-submit-button">
													<button
														className="post-comment-button"
														type="submit"
														onClick={() => {
															setData([...data, form]);
															setForm({
																Comment: ' ',
																Name: ' ',
																Gmail: ' ',
																Number: ' ',
															});
														}}
													>
														POST COMMENT
													</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Blogpopup;