import React from 'react';
import Contactform from './contact';

const Contactsection = () => {
	return (
		<div>
			<div className="contactus">
				<div className="contactex">
				
			<div className="conte_nt">
				<h1>GET A FREE QUOTATION TODAY!</h1>
				<p>Have any questions in mind?</p>
				<div className="call">
					<div className="call_icon"><i className='fas fa-phone'></i></div>
					<div className="call_number">
						<p>Say Hello</p>
						<h1>+91 9978686899</h1>
					</div>
				</div>
				<button className='btn'>Contact us</button>
			</div>
				</div>
				{/* <div className="contact">
					<div className="form">
						<h1>Meet a Designer</h1>
						<div className="input">
							<span>
								<i class="fas fa-user-alt"></i>
							</span>
							<input type="text" placeholder="enter yourname" />
						</div>
						<div className="input">
							<span>
								<i class="fas fa-envelope"></i>
							</span>
							<input type="text" placeholder="enter your email" />
						</div>
						<div className="input">
							<span>
								<i class="fas fa-phone"></i>
							</span>
							<input type="text" placeholder="enter your mobilenumber" />
						</div>

						<button className="btn bg">Submit</button>
					</div>
				</div> */}
				<div className="contact">
					<Contactform />
				</div>
			</div>
		</div>
	);
};

export default Contactsection;
