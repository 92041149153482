import axios from 'axios';
import { fetchDataRequest, fetchDataSuccess, fetchDataError } from './Action';

export default function fetchProducts() {
	return async (dispatch) => {
		dispatch(fetchDataRequest());
		await axios
			.get('https://kalyanihomeapi.kalyaniaura.com/api/product')
			.then((response) => {
				console.log(response.data);
				dispatch(fetchDataSuccess(response.data));
			})
			.catch((error) => {
				dispatch(fetchDataError(error));
			});
	};
}
