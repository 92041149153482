import React from 'react';
import Data from './api';
import { FaBed } from 'react-icons/fa';
import { useState } from 'react';

const Dslider = () => {
	const [num, setNum] = useState(0);
	let length = +Data.length - 2;


	const slidef = (e) => {
		if (num === length) {
			setNum(0);
		} else {
			setNum(+num + 1);
		}
	};
	const slideb = (e) => {
		if (num === 0) {
			setNum(length);
		} else {
			setNum(+num - 1);
		}
	};

	return (
		<div>
			<div className="cardheading">
			
				<div></div>
			</div>
			<div className="">
				<div className="slider">
					<span
						onClick={(e) => {
							slideb(e);
						}}
						className="arrow left"
					>
						<i class="fas fa-arrow-left"></i>
					</span>
					<span
						onClick={(e) => {
							slidef(e);
						}}
						className="arrow right"
					>
						<i class="fas fa-arrow-right"></i>
					</span>
					
					<div className="box">
						<div className="card">
							<div className="tag">new launch</div>
							<img src={Data[num].src} alt="" />
							<div className="cardcontent">
								<div className="heading">
									<h3 style={{ fontSize: '15px' }}>
										Kalyani Homes @ Nayandhalli
									</h3>
									<h4
										style={{
											fontSize: '13px',
											fontWeight: 200,
										}}
									>
										Nayandahalli Bangalore
									</h4>
								</div>
								<div>
									<div className="rate">
										<ul>
											<li>
												<FaBed />
											</li>
											<li>1,2,3</li>
										</ul>
										<button>60* lacs onwords</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Dslider;
