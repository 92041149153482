import React, { useState } from 'react';
import logo from '../images/kalyanilogo.jpeg';
import { FaBars } from 'react-icons/fa';

const Navbar = () => {
	let [bar, setBar] = useState(false);
	const menu = () => {
		if (bar === false) {
			setBar(true);
		} else {
			setBar(false);
		}
	};
	return (
		<div>
			<div className="navbar">
				<a href="/">
					<img src={logo} className="logo" alt="" />
				</a>
				<nav className={bar === false ? 'nav active' : 'nav'}>
					<a href="/">Home</a>
					<a href="/Categories">Categories</a>
					<a href="/Map">Map</a>
					<a href="/Location">Location </a>
					<a href="./Blog/Blogpage">Blog</a>
					<a href="#">Sixth Section</a>
				</nav>
				<FaBars className="menubar" onClick={menu} />
				<div className="buttons">
					<button className="btn bg">Get Register</button>
				</div>
			</div>
		</div>
	);
};

export default Navbar;
