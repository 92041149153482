import React from 'react';
import Data from './api';
import { FaBed } from 'react-icons/fa';
import { useState, } from 'react';
import { FaAngleRight } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setproduct } from '../../redux/Action';



const Slider = ({database}) => {
	const dispatch=useDispatch()
	const [num, setNum] = useState(0);
	let length = database.length-3;


let navigate = useNavigate();
const baseurl ='https://kalyanihomeadmin.kalyaniaura.com/storage/';

const push = () => {
	navigate('./Section2');

};


const detail=(e)=>{


dispatch(setproduct(e.target.getAttribute('value')));
	navigate('./Details');
}

	const slidef = (e) => {
		if (num === length) {
			setNum(0);
		} else {
			setNum(+num + 1);
		}
	};
	const slideb = (e) => {
		if (num === 0) {
			setNum(length);
		} else {
			setNum(+num - 1);
		}
	};
	return (
	
		<div className="slidersection">
			<dv
				className="sheading"
				style={{ color: 'white', cursor: 'pointer' }}
				onClick={push}
			>
				see All <FaAngleRight />
			</dv>
			<span
				onClick={(e) => {
					slideb(e);
				}}
				className="arrow lleft"
			>
				<i class="fas fa-arrow-left"></i>
			</span>
			{
				database.length===0?(<h1>Loading</h1>):
			
			(<div className="slider">
				<div className="box">
					<div
						className="card"
				
						onClick={(e) => {
							detail(e);
						}}
					>
						<div className="tag">new launch</div>
						<img
							value={num}
							src={baseurl + database[num].images[0].images}
							alt=""
						/>
						<div className="cardcontent">
							<div className="heading">
								<h3 style={{ fontSize: '15px' }}>
									Kalyani {database[num].product_name} @{' '}
									{database[num].location.name}
								</h3>
								<h4
									style={{
										fontSize: '13px',
										fontWeight: 200,
									}}
								>
									Nayandahalli Bangalore
								</h4>
							</div>
							<div>
								<div className="rate">
									<ul>
										<li>
											<FaBed />
										</li>
										<li>1,2,3</li>
									</ul>
									<button>60* lacs onwords</button>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="box">
					<div
						className="card"
					
						onClick={(e) => {
							detail(e);
						}}
					>
						<div className="tag">new launch</div>
						<img
							value={num+1}
							src={baseurl + database[num+1].images[0].images}
							alt=""
						/>
						<div className="cardcontent">
							<div className="heading">
								<h3 style={{ fontSize: '15px' }}>
									Kalyani {database[num+1].product_name} @{' '}
									{database[num+1].location.name}
								</h3>
								<h4
									style={{
										fontSize: '13px',
										fontWeight: 200,
									}}
								>
									Nayandahalli Bangalore
								</h4>
							</div>
							<div>
								<div className="rate">
									<ul>
										<li>
											<FaBed />
										</li>
										<li>1,2,3</li>
									</ul>
									<button>60* lacs onwords</button>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="box">
					<div
						className="card"
						
						onClick={(e) => {
							detail(e);
						}}
					>
						<div className="tag">new launch</div>
						<img
							value={num+2}
							src={baseurl + database[num+2].images[0].images}
							alt=""
						/>
						<div className="cardcontent">
							<div className="heading">
								<h3 style={{ fontSize: '15px' }}>
									Kalyani {database[num+2].product_name} @{' '}
									{database[num+2].location.name}
								</h3>
								<h4
									style={{
										fontSize: '13px',
										fontWeight: 200,
									}}
								>
									Nayandahalli Bangalore
								</h4>
							</div>
							<div>
								<div className="rate">
									<ul>
										<li>
											<FaBed />
										</li>
										<li>1,2,3</li>
									</ul>
									<button>60* lacs onwords</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>)
			}
			<span
				onClick={(e) => {
					slidef(e);
				}}
				className="arrow rright"
			>
				<i class="fas fa-arrow-right"></i>
			</span>
		</div>
	);
};

export default Slider;
