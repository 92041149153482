import React from 'react';

const Footer = () => {
	return (
		<div>
			<section>
				<div className="fcontainer">
					<div>
						<div className="detail">
							<span>
								<i class="fas fa-shield-alt"></i>
							</span>
							<h4>Up to 10-year warranty</h4>
							<p>
								Choose interiors designed with superior quality material,
								leaving no room for defects.
							</p>
						</div>
					</div>
					<div>
						<div className="detail">
							<span>
								<i class="fas fa-shield-alt"></i>
							</span>
							<h4>Up to 10-year warranty</h4>
							<p>
								Choose interiors designed with superior quality material,
								leaving no room for defects.
							</p>
						</div>
					</div>
					<div>
						<div className="detail">
							<span>
								<i class="fas fa-shield-alt"></i>
							</span>
							<h4>Up to 10-year warranty</h4>
							<p>
								Choose interiors designed with superior quality material,
								leaving no room for defects.
							</p>
						</div>
					</div>
					<div>
						<div className="detail">
							<span>
								<i class="fas fa-shield-alt"></i>
							</span>
							<h4>Up to 10-year warranty</h4>
							<p>
								Choose interiors designed with superior quality material,
								leaving no room for defects.
							</p>
						</div>
					</div>
				</div>
			</section>

			<section>
				<div className="summary">
					<h2>kalyani home interior design for you</h2>
					<p>
						Design your home interior's with our design experts. Our designer's will help you with options 
						and best interior's with superior quality of design. Get your interior's beautifully done with Kalyani Homes. 
					</p>
				</div>
			</section>

			<section class="footer">
				<div class="box-container">
					<div class="box">
						<h3>quick links</h3>
						<a href="/">Home</a>
						<a href="/Categories">Categories</a>
						<a href="/Map">Map</a>
						<a href="/Location">Location</a>
					</div>
					<div class="box">
						<h3>other links</h3>
						<a href="http://kalyanimotors.com/">kalyani motors </a>
						<a href="#">kalyani nexa</a>
						<a href="http://truevalue.kalyanimotors.com/">kalyani truevalue</a>
					</div>
					<div class="box">
						<h3>contact us</h3>
						<a href="#">
							<i class="fas fa-phone"></i> 123-245-6789{' '}
						</a>
						<a href="#">
							<i class="fas fa-phone"></i> 11-222-666-999
						</a>
						<a href="#">
							<i class="fas fa-envelope"></i> laxmandh@gmail.com
						</a>
						<a href="#">
							<i class="fab fa-location"></i> karnataka india -560000
						</a>
					</div>
					<div class="box">
						<h3>follow us</h3>
						<a href="#">
							<i class="fab fa-facebook"></i> facebook{' '}
						</a>
						<a href="#">
							<i class="fab fa-instagram"></i> instagram
						</a>
						<a href="#">
							<i class="fab fa-twitter"></i> twitter
						</a>
						<a href="#">
							<i class="fab fa-linkedin"></i> linked-in
						</a>
						<a href="#">
							<i class="fab fa-github"></i> git-hub
						</a>
					</div>
				</div>
				<div class="credit">
					<h1>
						created by <span style={{ color: '#ff7200' }}>Kalyani Motors</span>|
						all rights reserved
					</h1>
				</div>
			</section>
		</div>
	);
};

export default Footer;
