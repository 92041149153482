import React, { useState } from 'react';
import Singlesslideapi from '../Locationpage/Singleslideapi';


import {
	BsFillArrowLeftCircleFill,
	BsFillArrowRightCircleFill,
} from 'react-icons/bs';

const Singleslide = () => {
	const [open, setOpen] = useState(Singlesslideapi);
	const [num, setNum] = useState(0);
	const data = +Singlesslideapi.length - 1;
	console.log(num);

	const Leftbuttoncontainer = () => {
		if (num === 0) {
			setNum(data);
		} else {
			setNum(+num - 1);
		}
	};

	const Rightbuttoncontainer = () => {
		if (num === 0) {
			setNum(data);
		} else {
			setNum(+num - 1);
		}
	};
	const moveDot = (index) => {
		setNum(index);
	};
	return (
		<div className="Slide-container">
			<div className="single-sllide-container">
				<div className="img-container">
					<button
						className="left-button-container"
						onClick={Leftbuttoncontainer}
					>
						<BsFillArrowLeftCircleFill />
					</button>
					<img src={Singlesslideapi[num].src} alt="" />
					
					<button
						className="right-button-container"
						onClick={Rightbuttoncontainer}
					>
						<BsFillArrowRightCircleFill />
					</button>
				</div>
			</div>
			<div className="container-dots">
				{Array.from({ length: 3 }).map((item, index) => (
					<div
						onClick={() => moveDot(index + 0)}
						className={num === index + 0 ? 'dot active' : 'dot'}
					></div>
				))}
			</div>
		</div>
	);
};

export default Singleslide;
