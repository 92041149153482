import React from "react";
import { useState } from "react"
const Filter = () => {
const [rangevalue,setRangevalue]= useState(400000);
	const rangeupdate=(e)=>{
		setRangevalue(e.target.value)
	}
  return (
		<div>
			<div className="filter_box">
				<div className="filter">
					<div className="filter_button">
						<button className="fbtn">Commercial Space</button>
						<button className="fbtn">Apartments and Villas</button>
						<button className="fbtn">Rentals</button>
					</div>
					<div className="filt_content">
						<div className="type">
							<h2>Room Type</h2>
							<button className="type_btn">1 RK</button>
							<button className="type_btn">1 BHK</button>
							<button className="type_btn">2 BHK</button>
							<button className="type_btn">3 BHK</button>
						</div>
						<div className="range">
							<h2>Rent Range</h2>
							<div class="slidecontainer">
								<div className="rangevalue">
									<h3>
										<span>&#8377;- {rangevalue}</span>
									</h3>
								</div>
								<input
									type="range"
									min="0"
									max="500000"
									value={rangevalue}
									class="slider"
									id="myRange"
									onInput={(e) => {
										rangeupdate(e);
									}}
								/>
							</div>
						</div>

						<div className="availability">
							<h2>Availability</h2>
							<input type="radio" name="available" id="immediate" />
							<label htmlFor="immediate">Immediate</label>
							<input type="radio" name="available" id="15days" />
							<label htmlFor="15days">Within 15 Days</label>
							<input type="radio" name="available" id="30days" />
							<label htmlFor="30days">Within 30 Days</label>
							<input type="radio" name="available" id="30+days" />
							<label htmlFor="30+days">More than 30 Days</label>
						</div>
						<div className="tenants">
							<h2>Preffered Tenants</h2>
							<input type="checkbox" name="tenant" id="Immediate" />
							<label htmlFor="Immediate">Immediate</label>
							<input type="checkbox" name="tenant" id="Bachelor" />
							<label htmlFor="Bachelor">Bachelor</label>
							<input type="checkbox" name="tenant" id="company" />
							<label htmlFor="company">Company</label>
						</div>
						<div className="tenants">
							<h2>Furnishing</h2>
							<input type="checkbox" name="Furnish" id="Full" />
							<label htmlFor="Full">Full</label>
							<input type="checkbox" name="Furnish" id="Semi" />
							<label htmlFor="Semi">Semi</label>
							<input type="checkbox" name="Furnish" id="None" />
							<label htmlFor="None">None</label>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Filter