import React from 'react';

import { FaWhatsapp } from 'react-icons/fa';

const WtsapForm = ({ setChat }) => {
	const closeChat = () => {
		setChat(false);
	};
	return (
		<div className="Whatsp-form-conatiner">
			<div className="Whatsp-form-section">
				<button className="close-wtp-form" onClick={closeChat}>
					X
				</button>
				<div className="whatsp-logo-div">
					<div className="text-div-whatsp">
						<h3>Chat With us</h3>
						<p>
							Lorem ipsum dolor sit amet, consectetur adipisicing elit.
							Veritatis sunt facilis commodi facilis commodi facilis commodi
						</p>
					</div>
					<div className="chat-whastp-logo">
						<img
							src="https://www.stanzaliving.com/assets/chat-with-us.svg"
							alt=" "
						/>
					</div>
				</div>
				<div className="input-whatsp-div">
					<label>
						<select className="select-city-whatsp">
							<option>---Select Your City---</option>
							<option>Bangalore</option>
							<option>Mangalore</option>
							<option>Tumkuru</option>
							<option>Mysore</option>
							<option>Davangere</option>
							<option>Ramnagara</option>
							<option>Doddabalapura</option>
						</select>
					</label>
					<label>
						<select className="select-Locality-whatsp">
							<option>---Select Your Locality---</option>
							<option>Bangalore</option>
							<option>Mangalore</option>
							<option>Tumkuru</option>
							<option>Mysore</option>
							<option>Davangere</option>
							<option>Ramnagara</option>
							<option>Doddabalapura</option>
						</select>
					</label>
					<label>
						<select className="select-buy-whatsp">
							<option>---I'm A---</option>
							<option>Broker</option>
							<option>Direct Buyer</option>
						</select>
					</label>
				</div>
				<div className="whatsp-submit-div">
					<button className="whatsp-submit-button">
						<FaWhatsapp className="wtp-logo-color" />
						&nbsp;&nbsp;<p> Start Chat</p>
					</button>
				</div>
			</div>
		</div>
	);
};

export default WtsapForm;
