import React from 'react';
import Floor from './Floorapi';
import { useState } from 'react';
import Fpopup from './Fpopup';


const Floorplan = () => {
		const [filter, setFilter] = useState(Floor);
		const[floor,setFloor]=useState(false)
		
		// const [type,setType]=useState();

		const filterr = (e) => {
			if (e.target.value === 'all') {
				setFilter(Floor);
			} else {
				setFilter(
					Floor.filter((v) => {
						return v.type === e.target.value;
					})
				);
			}
		};
		const popset=()=>{
			setFloor(true)
		}

	return (
		<div>
			<Fpopup floor={floor} setFloor={setFloor}/>
			<div className="section">
				<h1>Floor Plan</h1>
				<div className="buttons">
					<div className="">
						<button
							className="btnsmall"
							value="all"
							onClick={(e) => {
								filterr(e);
							}}
						>
							All
						</button>
					</div>
					<div className="">
						<button
							className="btnsmall"
							value="master"
							onClick={(e) => {
								filterr(e);
							}}
						>
							Master Plan{' '}
						</button>
					</div>
					<div className="">
						<button
							className="btnsmall"
							value="2BHK"
							onClick={(e) => {
								filterr(e);
							}}
						>
							2BHK
						</button>
					</div>
					<div className="">
						<button
							className="btnsmall"
							value="3BHK"
							onClick={(e) => {
								filterr(e);
							}}
						>
							3BHK
						</button>
					</div>
					<div className="">
						<button
							className="btnsmall"
							value="route"
							onClick={(e) => {
								filterr(e);
							}}
						>
							Route map
						</button>
					</div>
				</div>
				<div className="plancontainer">
					{filter.map((v) => {
						return (
							<div>
								<div className="plan">
									<img src={v.src} alt="" onClick={popset}/>
									<h3>{v.title}</h3>
								</div>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
}

export default Floorplan