const subapi = [
	{
		id: 2,
		title: '2BHK MasterPlan',
		src: 'https://images.familyhomeplans.com/plans/80518/80518-1l.gif',
		type: '2BHK',
	},
	{
		id: 2,
		title: '2BHK MasterPlan',
		src: 'https://ulrichome.com/wp-content/uploads/2018/03/SHD-20120001-FOR-POST-Model.jpg?189db0&189db0',
		type: '2BHK',
	},
	{
		id: 2,
		title: '2BHK MasterPlan',
		src: 'https://ulrichome.com/wp-content/uploads/2018/03/SHD-20120001-FOR-POST-Model.jpg?189db0&189db0',
		type: '2BHK',
	},
	{
		id: 4,
		title: 'MasterPlan',
		src: 'https://www.researchgate.net/profile/Oliver-Penny/publication/340926474/figure/fig42/AS:884351344578575@1587857199789/Design-C-conceptual-master-plan.png',
		type: 'master',
	},
	{
		id: 4,
		title: 'MasterPlan',
		src: 'https://www.prestigeprimerosehills.ind.in/images/plans/thumbnail/master-plan.jpg',
		type: 'master',
	},
	{
		id: 4,
		title: 'MasterPlan',
		src: 'https://mir-s3-cdn-cf.behance.net/project_modules/fs/8e50b960694255.5a5624ef80a2a.jpg',
		type: 'master',
	},
	{
		id: 5,
		title: 'Route Map',
		src: 'http://www.eciecc.com/flash/ROUTEMAP.jpg',
		type: 'route',
	},
	{
		id: 5,
		title: 'Route Map',
		src: 'http://www.yukenindia.com/wp-content/uploads/2016/09/Bangalore-Map.jpg',
		type: 'route',
	},
	{
		id: 5,
		title: 'Route Map',
		src: 'https://fiverr-res.cloudinary.com/images/q_auto,f_auto/gigs/100151425/original/cb69b72333dc90d03fe3227ba3fa7786a840bb20/create-a-route-map.jpg',
		type: 'route',
	},
];

export default subapi;