import React, { useState } from 'react';

const Questions = () => {
	const [show,setShow]=useState("h");
	
	const hide=(e)=>{
			console.log(e.target.getAttribute('value'));
		if(show=="h"){
		
			setShow(e.target.getAttribute('value'));
			e.target.innerText="-"
		
		}else{
						setShow("h");
							e.target.innerText = '+';
		}
	}

	return (
		<div>
			<div className="questions">
				<h1>Frequently Asked Questions</h1>
				<div className="question_container">
					<div className="question_box">
						<div className="question">
							<div className="head">
								<h1>How can the property be shortlisted? </h1>
								<span value={0} onClick={(e) => hide(e)}>
									+
								</span>
							</div>

							<h3 className={show == "0" ? '' : 'active'}>
								Lorem ipsum, dolor sit amet consectetur adipisicing elit.
								Dolore, doloremque. Nulla perferendis aspernatur dolor rem?
							</h3>
						</div>
					</div>
					<div className="question_box">
						<div className="question">
							<div className="head">
								<h1>What details should I include when finding a property?</h1>
								<span value={1} onClick={(e) => hide(e)}>
									+
								</span>
							</div>

							<h3 className={show == "1" ? '' : 'active'}>
								Lorem ipsum, dolor sit amet consectetur adipisicing elit.
								Dolore, doloremque. Nulla perferendis aspernatur dolor rem?
							</h3>
						</div>
					</div>
					<div className="question_box">
						<div className="question">
							<div className="head">
								<h1>What are the benefits of searching a property in Kalyani Homes? </h1>
								<span value={2} onClick={(e) => hide(e)}>
									+
								</span>
							</div>

							<h3 className={show == "2" ? '' : 'active'}>
								Lorem ipsum, dolor sit amet consectetur adipisicing elit.
								Dolore, doloremque. Nulla perferendis aspernatur dolor rem?
							</h3>
						</div>
					</div>
					<div className="question_box">
						<div className="question">
							<div className="head">
								<h1>How do I check if the property shortlisted by me is also shortlisted by someone else?</h1>
								<span value={3} onClick={(e) => hide(e)}>
									+
								</span>
							</div>

							<h3 className={show == "3" ? '' : 'active'}>
								Lorem ipsum, dolor sit amet consectetur adipisicing elit.
								Dolore, doloremque. Nulla perferendis aspernatur dolor rem?
							</h3>
						</div>
					</div>
					<div className="question_box">
						<div className="question">
							<div className="head">
								<h1>How do I get in touch with the agent?</h1>
								<span value={4} onClick={hide}>
									+
								</span>
							</div>

							<h3 className={show == "4" ? '' : 'active'}>
								Lorem ipsum, dolor sit amet consectetur adipisicing elit.
								Dolore, doloremque. Nulla perferendis aspernatur dolor rem?
							</h3>
						</div>
					</div>
					<div className="question_box">
						<div className="question">
							<div className="head">
								<h1>How will I get to know the quotation of certain property?</h1>
								<span value={5} onClick={(e) => hide(e)}>
									+
								</span>
							</div>

							<h3 className={show == "5" ? '' : 'active'}>
								Lorem ipsum, dolor sit amet consectetur adipisicing elit.
								Dolore, doloremque. Nulla perferendis aspernatur dolor rem?
							</h3>
						</div>
					</div>
					<div className="question_box">
						<div className="question">
							<div className="head">
								<h1>In what all locations Kalyani Homes are available?</h1>
								<span value={6} onClick={(e) => hide(e)}>
									+
								</span>
							</div>

							<h3 className={show == "6" ? '' : 'active'}>
								Lorem ipsum, dolor sit amet consectetur adipisicing elit.
								Dolore, doloremque. Nulla perferendis aspernatur dolor rem?
							</h3>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Questions