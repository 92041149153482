const data = [
	{
		title: 'this is first one',
		src: 'https://images.pexels.com/photos/2251247/pexels-photo-2251247.jpeg?auto=compress&cs=tinysrgb&w=600',
		para: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Iusto illumprovident ipsum corrupti saepe harum amet nulla veniam, quos liberoperspiciatis repellendus. Exercitationem, temporibus dolores',
	},

	{
		title: 'this is second one',
		src: 'https://images.pexels.com/photos/1648838/pexels-photo-1648838.jpeg?auto=compress&cs=tinysrgb&w=600',
		para: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Iusto illumprovident ipsum corrupti saepe harum amet nulla veniam, quos liberoperspiciatis repellendus. Exercitationem, temporibus dolores',
	},
	{
		title: 'this is third',
		src: '	https://images.pexels.com/photos/2635038/pexels-photo-2635038.jpeg?auto=compress&cs=tinysrgb&w=600',
		para: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Iusto illumprovident ipsum corrupti saepe harum amet nulla veniam, quos liberoperspiciatis repellendus. Exercitationem, temporibus dolores',
	},
	{
		title: 'this is fourth',
		src: 'https://images.pexels.com/photos/323780/pexels-photo-323780.jpeg?auto=compress&cs=tinysrgb&w=600',
		para: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Iusto illumprovident ipsum corrupti saepe harum amet nulla veniam, quos liberoperspiciatis repellendus. Exercitationem, temporibus dolores',
	},
	{
		title: 'this is fifth',
		src: 'https://images.pexels.com/photos/2089698/pexels-photo-2089698.jpeg?auto=compress&cs=tinysrgb&w=600',
		para: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Iusto illumprovident ipsum corrupti saepe harum amet nulla veniam, quos liberoperspiciatis repellendus. Exercitationem, temporibus dolores',
	},
	{
		title: 'this is sixth',
		src: 'https://images.pexels.com/photos/2724749/pexels-photo-2724749.jpeg?auto=compress&cs=tinysrgb&w=600',
		para: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Iusto illumprovident ipsum corrupti saepe harum amet nulla veniam, quos liberoperspiciatis repellendus. Exercitationem, temporibus dolores',
	},
	{
		title: 'this is seventh one',
		src: 'https://images.pexels.com/photos/1080721/pexels-photo-1080721.jpeg?auto=compress&cs=tinysrgb&w=600',
		para: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Iusto illumprovident ipsum corrupti saepe harum amet nulla veniam, quos liberoperspiciatis repellendus. Exercitationem, temporibus dolores',
	},

	{
		title: 'this is eidth',
		src: 'https://images.pexels.com/photos/279607/pexels-photo-279607.jpeg?auto=compress&cs=tinysrgb&w=600',
		para: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Iusto illumprovident ipsum corrupti saepe harum amet nulla veniam, quos liberoperspiciatis repellendus. Exercitationem, temporibus dolores',
	},
	{
		title: 'this is ninth',
		src: '	https://images.pexels.com/photos/1571453/pexels-photo-1571453.jpeg?auto=compress&cs=tinysrgb&w=600',
		para: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Iusto illumprovident ipsum corrupti saepe harum amet nulla veniam, quos liberoperspiciatis repellendus. Exercitationem, temporibus dolores',
	},
	{
		title: 'this is tenth',
		src: 'https://images.pexels.com/photos/2631746/pexels-photo-2631746.jpeg?auto=compress&cs=tinysrgb&w=600',
		para: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Iusto illumprovident ipsum corrupti saepe harum amet nulla veniam, quos liberoperspiciatis repellendus. Exercitationem, temporibus dolores',
	},
	{
		title: 'this is eleventh',
		src: 'https://images.pexels.com/photos/1743227/pexels-photo-1743227.jpeg?auto=compress&cs=tinysrgb&w=600',
		para: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Iusto illumprovident ipsum corrupti saepe harum amet nulla veniam, quos liberoperspiciatis repellendus. Exercitationem, temporibus dolores',
	},
	{
		title: 'this is twelth',
		src: 'https://images.pexels.com/photos/2030037/pexels-photo-2030037.jpeg?auto=compress&cs=tinysrgb&w=600',
		para: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Iusto illumprovident ipsum corrupti saepe harum amet nulla veniam, quos liberoperspiciatis repellendus. Exercitationem, temporibus dolores',
	},
];

export default data;
