const initialState = {
	loading: false,
	Products: [],
	error: false,
	product_id: 0,
};

export default function Users(state = initialState, action) {
	switch (action.type) {
		case 'FETCH_DATA_REQUEST':
			return {
				...state,
				loading: true,
			};
		case 'FETCH_DATA_SUCCESS':
			return {
				...state,
				Products: action.payload,
			};
		case 'FETCH_DATA_FAILURE':
			return {
				...state,
				error: true,
			};
		case 'PRODUCT_ID':
			return {
				...state,
				product_id:action.payload
			};
	}
}
