const location = [
	{
		id: '01',
		title: 'RR Signatre',
		para: 'Emaar4 started its business operations in India business operations in India ',
		src: 'https://super.homelane.com/products/07dec18/entertainment_unit/6.jpg',
		price: '@93.96 L Onwards',
		Resident: 'Villa',
		add: 'Sarjapur Road',
		button: 'COMPLETED',
		type: 'gallery',
	},
	{
		id: '02',
		title: 'TVS Emerald Jardin',
		para: 'Emaar4 started its business operations in India business operations in India ',
		src: 'https://super.homelane.com/products/07dec18/entertainment_unit/7.jpg',
		price: '@96.96 L Onwards',
		Resident: '1BHK',
		add: 'in Sarjapur Road, Bangalore',
		button: 'ONGOING',
		type: 'gallery',
	},
	{
		id: '03',
		title: 'R9 Signatre',
		para: 'Emaar4 started its business operations in India business operations in India ',
		src: 'https://super.homelane.com/products/07dec18/entertainment_unit/8.jpg',
		price: '@97.96 L Onwards',
		Resident: '3BHK',
		add: 'Sarjapur Road',
		button: 'COMPLETED',
		type: 'gallery',
	},
	{
		id: '04',
		title: 'RR8 Signatre',
		para: 'Emaar4 started its business operations in India business operations in India ',
		src: 'https://super.homelane.com/products/07dec18/entertainment_unit/9.jpg',
		price: '@91.96 L Onwards',
		Resident: '4BHK',
		add: 'in Sarjapur Road, Bangalore',
		button: 'COMPLETED',
		type: 'gallery',
	},
	{
		id: '05',
		title: 'RR0 Signatre',
		para: 'Emaar4 started its business operations in India business operations in India ',
		src: 'https://super.homelane.com/products/07dec18/entertainment_unit/10.jpg',
		price: '@90.96 L Onwards',
		Resident: 'Villa',
		add: 'Sarjapur Road',
		button: 'COMPLETED',
		type: 'gallery',
	},
	{
		id: '06',
		title: 'Hosakerehalli',
		para: 'Emaar4 started its business operations in India business operations in India ',
		src: 'https://super.homelane.com/products/07dec18/entertainment_unit/11.jpg',
		price: '@99.96 L Onwards',
		Resident: '1BHK',
		add: 'in Sarjapur Road, Bangalore',
		button: 'COMPLETED',
		type: 'gallery',
	},
	{
		id: '07',
		title: 'Nagadevanahalli',
		para: 'Emaar4 started its business operations in India business operations in India',
		src: 'https://super.homelane.com/products/07dec18/entertainment_unit/12.jpg',
		price: '@93.96 L Onwards',
		Resident: '2BHK',
		add: 'Sarjapur Road',
		button: 'COMPLETED',
		type: 'gallery',
	},
	{
		id: '08',
		title: 'Ullal',
		para: 'Emaar4 started its business operations in India  business operations in India',
		src: 'https://super.homelane.com/products/07dec18/entertainment_unit/13.jpg',
		price: '@99.96 L Onwards',
		Resident: 'Villa',
		add: 'in Sarjapur Road, Bangalore',
		button: 'ONGOING',
		type: 'gallery',
	},

	
];
export default  location;