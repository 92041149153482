import React from 'react';
import Navbar from '../common/Navbar';
import Cardslider from '../Section4/Cardslider';
import Cardone from '../Section4/Cardone';
import Cardtwo from '../Section4/Cardtwo';
import Cardthree from '../Section4/Cardthree';
import Cardfour from '../Section4/Cardfour';
import Footer from '../common/Footer';
import Secpage from './Secpage';
import LocationSlide from './LocationSlide';
import Textslide from './Textslide';
import WtsapForm from './WtsapForm';
import { useState } from 'react';

import myImage from '../Section4/News letter icon-01.png';

const Sect4 = () => {
	const [chat, setChat] = useState(false);

	const Handlewtpchat = () => {
		if (chat === true) {
			setChat(false);
		} else {
			setChat(true);
		}
	};
	return (
		<div className="sec4-main-container">
			<Navbar />
			<Cardslider />
			<LocationSlide />
			<Textslide />
			<Secpage />
			<div className="cardpage-sect4-four-cards">
				<Cardone />
			</div>

			<div className="cardpage-sect4-four-cards">
				<Cardtwo />
			</div>

			<div className="cardpage-sect4-four-cards">
				<Cardthree />
			</div>
			<div className="cardpage-sect4-four-cards">
				<Cardfour />
				<div className={chat === false ? 'wtp-close' : ''}>
					<WtsapForm setChat={setChat} />
				</div>
			</div>
			<Footer />
			<div className="png-div-section">
				<div className="png-bar-div">
					<button className="chat-whatp-but" onClick={Handlewtpchat}>
						<img
							src="https://res.cloudinary.com/stanza-living/image/upload/v1654000747/Website%20v5/Icons/WhatsApp.png"
							alt=""
						/>
						<span> Chat With Us</span>
					</button>
				</div>
			</div>
		</div>
	);
};

export default Sect4;
