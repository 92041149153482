import React from 'react'
import { useState } from 'react';
import Apilocationimg from './Apilocationimg';
import {
	
	BsFillArrowLeftCircleFill,
	BsFillArrowRightCircleFill,
} from 'react-icons/bs';
import { AiFillRightCircle, AiFillLeftCircle } from 'react-icons/ai';


const DetailsSecond = () => {
const [sub, setSub] = useState(Apilocationimg);
const [section, setSection] = useState(0);
const move = +sub.length - 3;
const click = +sub.length - 3;
console.log(section);

	

const leftsecondslidelocation = () => {
	if (section === 0) {
		setSection(move);
	} else {
		setSection(+section - 1);
	}
};
const rightsecondslidelocation = () => {
	if (section === move) {
		setSection(0);
	} else {
		setSection(+section + 1);
	}
};
const leftthreeslidelocation = () => {
	if (section === 0) {
		setSection(click);
	} else {
		setSection(+section - 1);
	}
};
const rightthreeslidelocation = () => {
	if (section === click) {
		setSection(0);
	} else {
		setSection(+section + 1);
	}
};


	return (
		<div className="second-slider-location-container">
			<div className="three-icon-container">
				<div className="three-icon-section">
					<div className="three-icon-div">
						<img
							src="https://img.icons8.com/officel/170/000000/building.png"
							alt=""
						/>
						<h2>3,20,320+</h2>
						<h3>Properties & Counting...</h3>
					</div>
					<div className="three-icon-div">
						<img
							src="https://img.icons8.com/cotton/170/000000/building.png"
							alt=""
						/>
						<h2>3450+</h2>
						<h3>Properties Listed</h3>
					</div>
					<div className="three-icon-div">
						<img
							src="https://img.icons8.com/stickers/170/000000/building.png"
							alt=""
						/>
						<h2>3890+</h2>
						<h3>Buyers Contacted</h3>
					</div>
				</div>
			</div>
			<h1>Hand picked projects for you</h1>
			<div className="second-slider-location-section">
				<button
					className="left-second-slide-location"
					onClick={leftsecondslidelocation}
				>
					<BsFillArrowLeftCircleFill />
				</button>
				<div className="img-second-slide-location">
					<img src={sub[section].src} alt=" "  />
					<div className="text-second-slide-location">
						<h4>{sub[section].price}</h4>
						<h2>{sub[section].title}</h2>
						<p>{sub[section].add}</p>
						<h4>{sub[section].Resident}</h4>
					</div>
				</div>

				<div className="img-second-slide-location">
					<img src={sub[+section + 1].src} alt=" " />
					<div className="text-second-slide-location">
						<h4>{sub[+section + 1].price}</h4>
						<h2>{sub[+section + 1].title}</h2>
						<p>{sub[+section + 1].add}</p>
						<h4>{sub[+section + 1].Resident}</h4>
					</div>
				</div>
				<div className="img-second-slide-location">
					<img src={sub[+section + 2].src} alt=" " />
					<div className="text-second-slide-location">
						<h4>{sub[+section + 2].price}</h4>
						<h2>{sub[+section + 2].title}</h2>
						<p>{sub[+section + 2].add}</p>
						<h4>{sub[+section + 2].Resident}</h4>
					</div>
				</div>
				<button
					className="right-second-slide-location"
					onClick={rightsecondslidelocation}
				>
					<BsFillArrowRightCircleFill />
				</button>
			</div>
			<div className="collection-icon-conatiner">
				<h1>Collections for you </h1>
				<div className="collection-icon-section">
					<div className="icon-div-location">
						<img
							src="https://img.icons8.com/3d-fluency/80/000000/home.png"
							alt=""
						/>
						<h5>BUDGET HOMES</h5>
					</div>
					<div className="icon-div-location">
						<img
							src="https://img.icons8.com/pastel-glyph/80/000000/home.png"
							alt=""
						/>
						<h5>LUXURY HOMES</h5>
					</div>
					<div className="icon-div-location">
						<img
							src="https://img.icons8.com/hands/80/000000/experimental-home-hands.png"
							alt=""
						/>
						<h5>KIDS FRIENDLY HOMES</h5>
					</div>
					<div className="icon-div-location">
						<img
							src="https://img.icons8.com/dotty/80/000000/home-office.png"
							alt=""
						/>
						<h5>HOME OFFICE HOMES</h5>
					</div>
					<div className="icon-div-location">
						<img
							src="https://img.icons8.com/emoji/80/000000/house-emoji.png"
							alt=""
						/>
						<h5>STYLISH HOMES</h5>
					</div>
					<div className="icon-div-location">
						<img
							src="https://img.icons8.com/dusk/80/000000/home--v1.png"
							alt=""
						/>
						<h5>NATURE HOMES</h5>
					</div>
				</div>
			</div>

			<div className="three-slider-location-container">
				<h1>Recently Added Properties</h1>
				<div className="three-slider-location-sub-section">
					<div className="three-slider-location-section">
						<button
							className="left-three-slide-location"
							onClick={leftthreeslidelocation}
						>
							<AiFillLeftCircle />
						</button>

						<div className="img-three-slide-location">
							<button className="img-title-three-button">
								{sub[section].button}
							</button>
							<img src={sub[section].src} alt=" " />
							<div className="text-three-slide-location">
								<h2>{sub[section].title}</h2>
								<h4>{sub[section].price}</h4>
								<p>{sub[section].add}</p>
								<h4>{sub[section].Resident}</h4>
								<div className="info-slider-three-div">
									<button className="contact-button-three-slider">
										GET IN TOUCH
									</button>
								</div>
							</div>
						</div>

						<div className="img-three-slide-location">
							<button className="img-title-three-button">
								{sub[+section + 1].button}
							</button>
							<img src={sub[+section + 1].src} alt=" " />
							<div className="text-three-slide-location">
								<h2>{sub[+section + 1].title}</h2>
								<h4>{sub[+section + 1].price}</h4>
								<p>{sub[+section + 1].add}</p>
								<h4>{sub[+section + 1].Resident}</h4>
								<div className="info-slider-three-div">
									<button className="contact-button-three-slider">
										GET IN TOUCH
									</button>
								</div>
							</div>
						</div>

						<div className="img-three-slide-location">
							<button className="img-title-three-button">
								{sub[+section + 2].button}
							</button>
							<img src={sub[+section + 2].src} alt=" " />
							<div className="text-three-slide-location">
								<h2>{sub[+section + 2].title}</h2>
								<h4>{sub[+section + 2].price}</h4>
								<p>{sub[+section + 2].add}</p>
								<h4>{sub[+section + 2].Resident}</h4>
								<div className="info-slider-three-div">
									<button className="contact-button-three-slider">
										GET IN TOUCH
									</button>
								</div>
							</div>
						</div>
						{/* <div className="three-slider-div-parts">
						<div className="img-three-slide-location">
							<img src={sub[+section + 3].src} alt=" " />
							<div className="text-three-slide-location">
								<h2>{sub[+section + 3].title}</h2>
								<h4>{sub[+section + 3].price}</h4>
								<p>{sub[+section + 3].add}</p>
								<h4>{sub[+section + 3].Resident}</h4>
							</div>
						</div>
					</div> */}
						<button
							className="right-three-slide-location"
							onClick={rightthreeslidelocation}
						>
							<AiFillRightCircle />
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}

export default DetailsSecond;