import React from 'react';
import image from '../images/reliablehomes2.png';

const Fpopup = ({floor,setFloor}) => {
console.log(floor)
const setfpop=()=>{
	setFloor(false)
}
	
	return (
		<div>
			<div className={floor === true ? 'floor_pop' : 'floor_pop disabled'}>
				<div className="floor_section">
					<span className="close" onClick={setfpop}>
						<i class="fas fa-times"></i>
					</span>
					<div className="floor_img">
						<img src={image} alt="" />
					</div>
					<div className="floor_cont">
						<div className="floor_fcont">
							<div className="fcont_f">
								<h1>
									<span> 3BHK </span>
									-2750 sqr ft
								</h1>
								<p>
									Lorem ipsum dolor sit amet consectetur adipisicing elit.
									Repudiandae inventore asperiores pariatur explicabo ipsum
									alias dolores suscipit veritatis tempore voluptas?
								</p>
							</div>
							<div className="fcont_s">
								<h3>Description</h3>
								<table>
									<tr>
										<th>Master Bedroom</th>
										<td>270 sq ft</td>
									</tr>
									<tr>
										<th>Bedroom</th>
										<td>170 Sq ft</td>
									</tr>
									<tr>
										<th>Hall</th>
										<td>500 Sq ft</td>
									</tr>
									<tr>
										<th>Kitchen</th>
										<td>100 sq ft</td>
									</tr>
								</table>
							</div>
						</div>
						<div className="floor_gallery">
							<div className="fimage">
								<img
									src="https://images.pexels.com/photos/1454806/pexels-photo-1454806.jpeg?auto=compress&cs=tinysrgb&w=600"
									alt=""
								/>
							</div>
							<div className="fimage">
								<img
									src="https://images.pexels.com/photos/262048/pexels-photo-262048.jpeg?auto=compress&cs=tinysrgb&w=600"
									alt=""
								/>
							</div>
							<div className="fimage">
								<img
									src="https://images.pexels.com/photos/2631746/pexels-photo-2631746.jpeg?auto=compress&cs=tinysrgb&w=60"
									alt=""
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Fpopup;