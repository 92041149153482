import React, { useState } from 'react';
import '../../css/Locationpage/Location.css';
import { AiFillHeart } from 'react-icons/ai';
import data from './Locationapi';
// 'https://source.unsplash.com/user/c_v_r/1600x900';
import img1 from './assets/56101597SD00023_A1_803x602.jpg';
import img2 from './assets/DesignedbyEmilyHendersonDesign_PhotobySaraTramp_8-10c43b6e9b6a4f529313664f218e5721.jpg';
import img3 from './assets/tumblr_7d25c42c5d381f48302ee74b7566c532_0f5eb31e_1280.jpg';
import img4 from './assets/Pooja-room-designs-suited-for-Indian-homes-17.jpg';
import img5 from './assets/Lp-Kids-play-area.jpg';
import img6 from './assets/56101597SD00023_A1_803x602.jpg';
import img7 from './assets/kitchen-loft-design-for-your-home.jpg';
import logoanimation from '../Locationpage/assets/logoanimation.mp4';
import Navbar from '../common/Navbar';
import Footer from '../common/Footer';
import Singleslide from '../Locationpage/Singleslide';

const arr = [img1, img2, img3, img4, img5, img6, img7];

function ListDataContainer() {
	const [locat, setLocat] = useState(
		'https://maps.google.com/maps?q=27.2046,77.4977&hl=es&z=14&amp;output=embed'
	);
	const [locat2, setLocat2] = useState(
		'https://www.google.com/maps/d/embed?mid=1FPyfl0fhpO7s5c-ocKBNWgpn2L7ZVlw&ehbc=2E312F'
	);

	console.log(locat);

	// let src_var =

	const _updateLocation = (e) => {
		console.log(e.target.getAttribute('location_src'));
		setLocat(e.target.getAttribute('location_src'));
		setLocat2(e.target.getAttribute('location'))

	};
	const iframe2=`<iframe
		src=${locat2}
		width="100%"
		height="100%"
		frameborder="0"
		marginheight="0"
		marginwidth="0"
	></iframe>`;

	const iframe = `	<iframe
							width="100%"
							height="100%"
							frameborder="0"
							marginheight="0"
							marginwidth="0"
							src=${locat}
						></iframe>`;

	return (
		<div>
			<Navbar />
			<div className="single-div-slide-bar">
				<Singleslide />
			</div>
			<div className="superMom">
				<div className="motherShip">
					<div className="left-cont">
						<div className="listDataContainer">
							{data.map((item, idx) => {
								return (
									<div className="listItem" key={item.id} id={item.i_d}>
										<div className="imageContainer">
											<img
												src={arr[idx]}
												alt="img"
												value={idx}
												location_src={item.location_src}
												location={item.location}
												onMouseEnter={(e) => {
													_updateLocation(e);
												}}
											/>
										</div>
										<div className="contentContainer">
											<div className="property-details">
												<div className="detail-header">
													<div className="property-heading">
														<h2>{item.name}</h2>
													</div>
													<div className="heart-icon">
														<AiFillHeart color="red" />
													</div>
												</div>
												<div className="location-container">
													<h3>{item.Address}</h3>
												</div>
												<div className="gender-occupancy">
													<div className="gender">
														<img
															className="gender-icon"
															src="https://res.cloudinary.com/stanza-living/image/upload/v1654244155/Website%20v5/Icons/unisex-listing.png"
															alt="img"
														/>
														<span class="fm">Unisex</span>
													</div>

													<div className="separator-div"></div>

													<div className="occupancy">
														<span class="fm">Double, Triple</span>
														<img
															class="occupancy-icon"
															src="https://res.cloudinary.com/stanza-living/image/upload/v1652942218/Website%20v5/Icons/occupancy.png"
															alt="img"
														/>
													</div>
												</div>
												<div className="amenities-wrap">
													<div class="amenity-title">Amenities</div>
													<div class="amenities-pills-wrap">
														<div class="amenity-pill">
															<img
																src="https://res.cloudinary.com/stanza-living/image/upload/v1655201072/Website%20v5/Amenities-Services/Attached_Washroom.png"
																alt="Attached Washroom"
															/>
															<div class="amenity-name">Attached Washroom</div>
														</div>
														<div class="amenity-pill">
															<img
																src="https://res.cloudinary.com/stanza-living/image/upload/v1655201074/Website%20v5/Amenities-Services/Window.png"
																alt="Window"
															/>
															<div class="amenity-name">Window</div>
														</div>
													</div>
												</div>
												<div className="price-action-buttons">
													<div className="starts-from">
														<div className="starts-from-label">Starts from</div>
														<div className="starts-from-price">
															{'₹' + item.price + '/mo*'}
														</div>
													</div>
													<div className="action-buttons">
														<button className="styles__GreenBtn">
															Schedule a visit
														</button>
														<button className="styles__GreenOutlineBtn styles__GreenBtn">
															Unlock Discount
														</button>
													</div>
												</div>
											</div>
										</div>
									</div>
								);
							})}
						</div>
					</div>
					<div className="right-cont">
						<div className="loader">
							<video loop autoplay="" muted>
								<source src={logoanimation} type="video/mp4" />
							</video>
						</div>
						<div
							className="mapContainer"
							dangerouslySetInnerHTML={{ __html: iframe2 }}
						/>
						{/* <MyMapComponent isMarkerShown /> */}
						{/* <iframe
							width="100%"
							height="100%"
							frameborder="0"
							marginheight="0"
							marginwidth="0"
							src={locat}
						></iframe> */}
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
}

export default ListDataContainer;
