import React from 'react'
import { useState } from 'react';
import ApiSubgallery from './Apilocationimg'
import { AiOutlineDoubleRight, AiOutlineDoubleLeft } from 'react-icons/ai';
import {
	BsFillArrowDownCircleFill,
	BsFillArrowUpCircleFill,
} from 'react-icons/bs';
import Navbar from '../common/Navbar';
import Footer from '../common/Footer';
import Masterapi from './Masterapi';
import { GiWaterDrop, } from 'react-icons/gi';
import { GiCannon } from 'react-icons/gi';
import { FaGooglePlay } from 'react-icons/fa';
import { TbDeviceCctv } from 'react-icons/tb';
import { FaCreativeCommonsBy } from 'react-icons/fa';







const Subpage = () => {
	const [sub , setSub] =useState(ApiSubgallery)
	const [data, setData] = useState(Masterapi);
	const [open, setOpen] =useState(1)
	const click = +sub.length - 1;
	console.log(open);
	
	
const [show, setShow] = useState(true);
const [gallery, setGallery] =useState(false);
const [over, setOver] =useState(false);
 
// const [chat, setChat] = useState(false);

// const Handlewtpchat = () => {
// 	if (chat === true) {
// 		setChat(false);
// 	} else {
// 		setChat(true);
// 	}
// };
	
	const Filtterr =(e) =>{
   if(e.target.value === "all"){
		setData(Masterapi)
	 }else{
		setData(
		Masterapi.filter((v,i)=>{
			console.log(v)
			return v.type===e.target.value;
		}));
	 }
	}

	const leftbuttongallery =()=>{
		if(open === 0){
			setOpen(click)
		}else{
			setOpen(+open-1)
		}
	}

	const rightbuttongallery =()=>{
		if(open=== click){
			setOpen(0)
		}else{
			setOpen(+open+1)
		}
	}

	return (
		<div className="subdetaillocation-page-container-subpage">
			<Navbar />
			<div className="subdetailocation-page-section-subpage">
				<div className="heading-page-price-div-subpage">
					<div className="name-heading-subpage-row">
						<h2>Kalyani Homes Life</h2>
						<p> In : Nayandahalli</p>
					</div>
					<div className="price-heading-subpage-row">
						<h2>&#2353;90.60 L</h2>
						<p>onwards</p>
					</div>
				</div>
				<div className="button-div-bar-subpage-overview">
					<div className="button-subsection-subpage">
						<button
							className="button-div-subpage-four"
							onClick={() => {
								setShow(true);
								setGallery(false);
								setOver(false);
							}}
						>
							<BsFillArrowDownCircleFill className="button-action-div-symbl" />
							&nbsp; GALLERY
						</button>
						<button
							className="button-div-subpage-four"
							onClick={() => {
								setGallery(true);
								setShow(false);
								setOver(false);
							}}
						>
							<BsFillArrowDownCircleFill className="button-action-div-symbl" />
							&nbsp; OVERVIEW
						</button>
						<button
							className="button-div-subpage-four"
							onClick={() => {
								setOver(true);
								setShow(false);
								setGallery(false);
							}}
						>
							<BsFillArrowDownCircleFill className="button-action-div-symbl" />
							&nbsp; LOCATION
						</button>
						{/* <button className="button-div-subpage-four">
							<BsFillArrowDownCircleFill className="button-action-div-symbl" />
							&nbsp; BUY
						</button>
						<button className="button-div-subpage-four">
							<BsFillArrowDownCircleFill className="button-action-div-symbl" />
							&nbsp; RENT
						</button> */}
					</div>
				</div>
				{show === true ? (
					<>
						<div className="gallery-section-button-container">
							<div className="gallery-section-button-section">
								<div className="gallery-section-div-row">
									<button
										className="left-button-gallery"
										onClick={leftbuttongallery}
									>
										<AiOutlineDoubleLeft />
									</button>
									<div>
										<div>
											<div className="img-div-bar-section">
												<img src={sub[open].src} alt="" />
											</div>
										</div>
									</div>
									<button
										className="right-button-gallery"
										onClick={rightbuttongallery}
									>
										<AiOutlineDoubleRight />
									</button>
								</div>
							</div>
						</div>
						<div className="gallery-button-four-plans-container">
							<div className="gallery-button-four-plans-section">
								<button
									className="button-four-plans"
									value="2BHK"
									onClick={(e) => {
										Filtterr(e);
									}}
								>
									<BsFillArrowDownCircleFill className="four-plans-symbl-button" />
									&nbsp; 2BHK
								</button>
								<button
									className="button-four-plans"
									value="master"
									onClick={(e) => {
										Filtterr(e);
									}}
								>
									<BsFillArrowDownCircleFill className="four-plans-symbl-button" />
									&nbsp; MASTER
								</button>
								<button
									className="button-four-plans"
									value="route"
									onClick={(e) => {
										Filtterr(e);
									}}
								>
									<BsFillArrowDownCircleFill className="four-plans-symbl-button" />
									&nbsp; ROUTE
								</button>
								<button
									className="button-four-plans"
									value="all"
									onClick={(e) => {
										Filtterr(e);
									}}
								>
									<BsFillArrowDownCircleFill className="four-plans-symbl-button" />
									&nbsp; ALL
								</button>
							</div>
						</div>
						<div>
							<div className="gallery-section-button-container-two">
								<div className="gallery-section-button-section-two">
									<div className="gallery-section-div-row-two">
										<div className="gallery-section-img-div-flex">
											{data.map((e) => {
												return (
													<div>
														<div className="img-div-bar-section-two">
															<img src={e.src} alt="" />
														</div>
													</div>
												);
											})}
										</div>
									</div>
								</div>
							</div>
						</div>
					</>
				) : (
					<div> </div>
				)}
				{gallery === true ? (
					<div className="overview-button-slide-container-row">
						<div className="overview-button-slide-section-row">
							<div className="gallery-row-part-section">
								<div className="img-section-div-row-gallery">
									<img src={sub[+open + 4].src} alt=" " />
								</div>
								{/* <div className="png-div-section">
									<div className="png-bar-div">
										<button className="chat-whatp-but" onClick={Handlewtpchat}>
											<img
												src="https://media.istockphoto.com/vectors/red-call-icon-vector-id971654072?k=20&m=971654072&s=170667a&w=0&h=p2SH3vlIJCbFaEE7PfpzG6MgtChfj_G9YfW5tZmQhNA="
												alt=""
											/>
											<span> Chat With Us</span>
										</button>
									</div>
								</div> */}
								<div className="ul-tages-gallery-row-parts">
									<table className="table-tag-row">
										<tr>
											<th>BHK</th>
											<th>Area</th>
										</tr>
										<tr>
											<td>2,3,4, BHK</td>
											<td>1292-2371 sq.ft</td>
										</tr>
										<tr>
											<th>Possession</th>
											<th>Price Range</th>
										</tr>
										<tr>
											<td>Jun-2025</td>
											<td>&#2353;91.96 L - 93.54 L</td>
										</tr>
										<tr>
											<th>Property Type</th>
											<th>Launched Date</th>
										</tr>
										<tr>
											<td>Apartment</td>
											<td>Jun-2022</td>
										</tr>
									</table>
								</div>
							</div>
							<div className="unit-div-row-table-conatiner">
								<h2>Unit Configuration</h2>
								<div className="unit-div-row-table-section">
									<table className="unit-table-tag-div">
										<tr>
											<th>Unit Types</th>
											<th>Super Built-Up Area</th>
											<th>Carpet Area</th>
											<th>Price</th>
											<th>Floor Plans | Live-in Tour</th>
										</tr>
										<tr>
											<td>
												<h4>2 BHK Apartment</h4>
												<p>Availibility * Yes</p>
											</td>
											<td>1292-1315 sq.ft</td>
											<td>NA</td>
											<td>&#2353;91.67 L-93.54 L</td>
											<td>NA</td>
										</tr>
										<tr>
											<td>
												<h4>2 BHK Apartment</h4>
												<p>Availibility * Yes</p>
											</td>
											<td>1292-1315 sq.ft</td>
											<td>NA</td>
											<td>&#2353;91.67 L-93.54 L</td>
											<td>NA</td>
										</tr>
										<tr>
											<td>
												<h4>2 BHK Apartment</h4>
												<p>Availibility * Yes</p>
											</td>
											<td>1292-1315 sq.ft</td>
											<td>NA</td>
											<td>&#2353;91.67 L-93.54 L</td>
											<td>NA</td>
										</tr>
										<tr>
											<td>
												<h4>2 BHK Apartment</h4>
												<p>Availibility * Yes</p>
											</td>
											<td>1292-1315 sq.ft</td>
											<td>NA</td>
											<td>&#2353;91.67 L-93.54 L</td>
											<td>NA</td>
										</tr>
									</table>
								</div>
							</div>
							<div className="map-gallery-overview-div-row-container">
								<div className="map-gallery-overview-div-row-section">
									<div className="map-gallery-location">
										<h2>Location</h2>
										<iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3888.423193850506!2d77.52666761482176!3d12.94475009087346!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTLCsDU2JzQxLjEiTiA3N8KwMzEnNDMuOSJF!5e0!3m2!1sen!2sin!4v1663322922697!5m2!1sen!2sin"></iframe>
									</div>
								</div>
							</div>
							<div className="amenities-overview-div-row-container">
								<h2>Amenities- Kalyani Homes Life</h2>
								<div className="amenities-overview-div-row-section">
									<div className="icon-overview-div-row">
										<ul>
											<li>
												<button className="button-four-plans-amenities">
													<GiWaterDrop className="four-plans-symbl-button" />
													&nbsp; 24Hrs Water Supply
												</button>
											</li>
											<li>
												<button className="button-four-plans-amenities">
													<GiCannon className="four-plans-symbl-button" />
													&nbsp; Badminton Court
												</button>
											</li>
											<li>
												<button className="button-four-plans-amenities">
													<FaGooglePlay className="four-plans-symbl-button" />
													&nbsp; Business Center
												</button>
											</li>
											<li>
												<button className="button-four-plans-amenities">
													<TbDeviceCctv className="four-plans-symbl-button" />
													&nbsp; CCTV Cameras
												</button>
											</li>
											<li>
												<button className="button-four-plans-amenities">
													<GiWaterDrop className="four-plans-symbl-button" />
													&nbsp; 24Hrs Water Supply
												</button>
											</li>
											<li>
												<button className="button-four-plans-amenities">
													<GiCannon className="four-plans-symbl-button" />
													&nbsp; Badminton Court
												</button>
											</li>
											<li>
												<button className="button-four-plans-amenities">
													<FaGooglePlay className="four-plans-symbl-button" />
													&nbsp; Business Center
												</button>
											</li>
											<li>
												<button className="button-four-plans-amenities">
													<TbDeviceCctv className="four-plans-symbl-button" />
													&nbsp; CCTV Cameras
												</button>
											</li>
										</ul>
									</div>
									<div className="icon-overview-div-row">
										<ul>
											<li>
												<button className="button-four-plans-amenities">
													<GiWaterDrop className="four-plans-symbl-button" />
													&nbsp; 24Hrs Water Supply
												</button>
											</li>
											<li>
												<button className="button-four-plans-amenities">
													<GiCannon className="four-plans-symbl-button" />
													&nbsp; Badminton Court
												</button>
											</li>
											<li>
												<button className="button-four-plans-amenities">
													<FaGooglePlay className="four-plans-symbl-button" />
													&nbsp; Business Center
												</button>
											</li>
											<li>
												<button className="button-four-plans-amenities">
													<TbDeviceCctv className="four-plans-symbl-button" />
													&nbsp; CCTV Cameras
												</button>
											</li>
											<li>
												<button className="button-four-plans-amenities">
													<GiWaterDrop className="four-plans-symbl-button" />
													&nbsp; 24Hrs Water Supply
												</button>
											</li>
											<li>
												<button className="button-four-plans-amenities">
													<GiCannon className="four-plans-symbl-button" />
													&nbsp; Badminton Court
												</button>
											</li>
											<li>
												<button className="button-four-plans-amenities">
													<FaGooglePlay className="four-plans-symbl-button" />
													&nbsp; Business Center
												</button>
											</li>
											<li>
												<button className="button-four-plans-amenities">
													<TbDeviceCctv className="four-plans-symbl-button" />
													&nbsp; CCTV Cameras
												</button>
											</li>
										</ul>
									</div>
									<div className="icon-overview-div-row">
										<ul>
											<li>
												<button className="button-four-plans-amenities">
													<GiWaterDrop className="four-plans-symbl-button" />
													&nbsp; 24Hrs Water Supply
												</button>
											</li>
											<li>
												<button className="button-four-plans-amenities">
													<GiCannon className="four-plans-symbl-button" />
													&nbsp; Badminton Court
												</button>
											</li>
											<li>
												<button className="button-four-plans-amenities">
													<FaGooglePlay className="four-plans-symbl-button" />
													&nbsp; Business Center
												</button>
											</li>
											<li>
												<button className="button-four-plans-amenities">
													<TbDeviceCctv className="four-plans-symbl-button" />
													&nbsp; CCTV Cameras
												</button>
											</li>
											<li>
												<button className="button-four-plans-amenities">
													<GiWaterDrop className="four-plans-symbl-button" />
													&nbsp; 24Hrs Water Supply
												</button>
											</li>
											<li>
												<button className="button-four-plans-amenities">
													<GiCannon className="four-plans-symbl-button" />
													&nbsp; Badminton Court
												</button>
											</li>
											<li>
												<button className="button-four-plans-amenities">
													<FaGooglePlay className="four-plans-symbl-button" />
													&nbsp; Business Center
												</button>
											</li>
											<li>
												<button className="button-four-plans-amenities">
													<TbDeviceCctv className="four-plans-symbl-button" />
													&nbsp; CCTV Cameras
												</button>
											</li>
										</ul>
									</div>
								</div>
							</div>
							<div className="specification-div-row-container">
								<h2>Specifications-Kalyani Homes The Life</h2>
								<div className="specification-div-row-section">
									<div className="ul-specification-div-row">
										<h3>General Specification</h3>
										<ul>
											<li>
												1. RCC framed structure for all Towers on Aluminium
												formwork
											</li>
											<li>2.Vitrified tiles for lobby area for all Towers,</li>
											<li>
												3. Staircase treads & landing and skirting in granite
												for ground floor and tiles for typical floor
											</li>
											<li>
												4. Gyp board false ceiling and concealed lighting for
												ground floor lift lobby area
											</li>
										</ul>
									</div>
									<div className="ul-specification-div-row">
										<h3>Residential Units</h3>
										<ul>
											<li>
												1.Interior: Emulsion for internal walls and ceilings
											</li>
											<li>2.Exterior: Water resistant texture paint</li>
											<li>
												3. Flooring: Living / Dining / bedrooms/balcony-
												vitrified tiles
											</li>
											<li>4.MS railing for all balconies</li>
										</ul>
									</div>
								</div>
							</div>
							<div className="content-div-row-container">
								<h2>Kalyani Homes</h2>
								<div className="content-div-row-section">
									<p>
										Since inception in 2013, our philosophy has always been to
										create homes that take mankind to the future by bringing
										nature and technology together. This promise of sustainable
										living has been aptly complemented by smart designs and
										signature lifestyle choices. By nestling your every step
										with lush greenery and fresh air, we've restored the purity
										of nature. And by introducing your distinct tastes to
										contemporary amenities, we've created the future for you.
										But we haven't stopped there we've lent thoughtfulness to
										our creativity with a transparent, hands-on approach that
										combines the best of planning and execution to deliver
										uninhibited happiness.
									</p>
								</div>
							</div>
						</div>
					</div>
				) : (
					<div> </div>
				)}
				{over === true ? (
					<div className="location-button-slide-container-row">
						<div className="location-button-slide-section-row">
							<h2>Nearby Places and Landmarks</h2>
							<div className="landmark-button-section">
								<div className="places-distance-div-row">
									<div className="places-div-row">
										<div className="icon-div-row">
											<ul>
												<li>
													<FaCreativeCommonsBy />
												</li>
												<li>
													<FaCreativeCommonsBy />
												</li>
												<li>
													<FaCreativeCommonsBy />
												</li>
												<li>
													<FaCreativeCommonsBy />
												</li>
												<li>
													<FaCreativeCommonsBy />
												</li>
											</ul>
										</div>
										<div className="place-names-div">
											<ul>
												<li>H M Tech Park</li>
												<li>ITPL Tech Park</li>
												<li>Sharada Vidya Mandira Kadugod</li>
												<li>Ujjval Vidyalaya Composite</li>
												<li>Narayana Multispeciality Hospital</li>
											</ul>
										</div>
									</div>
									<div className="distance-div-row">
										<ul>
											<li>4.6 Km</li>
											<li>6.3 Km</li>
											<li>3.3 Km</li>
											<li>5.1 Km</li>
											<li>6.6 Km</li>
										</ul>
									</div>
								</div>
								<div className="places-distance-div-row">
									<div className="places-div-row">
										<div className="icon-div-row">
											<ul>
												<li>
													<FaCreativeCommonsBy />
												</li>
												<li>
													<FaCreativeCommonsBy />
												</li>
												<li>
													<FaCreativeCommonsBy />
												</li>
												<li>
													<FaCreativeCommonsBy />
												</li>
												<li>
													<FaCreativeCommonsBy />
												</li>
											</ul>
										</div>
										<div className="place-names-div">
											<ul>
												<li>H M Tech Park</li>
												<li>ITPL Tech Park</li>
												<li>Sharada Vidya Mandira Kadugod</li>
												<li>Ujjval Vidyalaya Composite</li>
												<li>Narayana Multispeciality Hospital</li>
											</ul>
										</div>
									</div>
									<div className="distance-div-row">
										<ul>
											<li>4.6 Km</li>
											<li>6.3 Km</li>
											<li>3.3 Km</li>
											<li>5.1 Km</li>
											<li>6.6 Km</li>
										</ul>
									</div>
								</div>
							</div>
							<div className="map-subpage-location-container">
								<div className="map-subpage-location-section">
									<h2>Near By Places</h2>
									<div className="map-page-row-div-subpage">
										<div className="text-area-subpage-div">
											<div className="input-area-subpagr">
												<ul>
													<li>
														<input type="radio" />
														&nbsp;&nbsp;Bus Stops
													</li>
													<li>
														<input type="radio" />
														&nbsp;&nbsp;Metro Station
													</li>
													<li>
														<input type="radio" />
														&nbsp;&nbsp; Hospitals & Clinics
													</li>
													<li>
														<input type="radio" />
														&nbsp;&nbsp;Malls
													</li>
													<li>
														<input type="radio" />
														&nbsp;&nbsp;Day Cares & Schools
													</li>
												</ul>
											</div>
										</div>
										<div className="map-page-subpage-div-row">
											<div className="map-section-div-row-ifre">
												<iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3888.423193850506!2d77.52666761482176!3d12.94475009087346!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTLCsDU2JzQxLjEiTiA3N8KwMzEnNDMuOSJF!5e0!3m2!1sen!2sin!4v1663322922697!5m2!1sen!2sin"></iframe>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				) : (
					<div> </div>
				)}
			</div>
			<div className="request-form-subpage-container">
				<div className="reques-form-subpage-section">
					<div className="form-heading-name-subpage">
						<h2>Get In Touch</h2>
					</div>
					<div className="input-form-subpage-div">
						<input type="name" placeholder="Enter Your Name" />
						<input type="Email" placeholder="Enter Your Email" />
						<input type="Number" placeholder="Enter Your Number" />
					</div>
					<div className="checkbox-form-subpage-div">
						<input type="Checkbox" className="checkboxsize" />
						<p>Lorem ipsum, dolor sit amet consectetur adipisicing elit</p>
					</div>
					<div className="button-form-subpage-div">
						<button className="button-subpage-button">REQUEST CALL BACK</button>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
}

export default Subpage;