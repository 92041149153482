import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Main from './Main';
import { useState, useEffect} from 'react';
import Cardsection from './components/section2/Cardsection';
// import Contact from './components/Details/Contact';
// import Banner from './components/common/Banner';
import Detail from './components/Details/Detail';
import Locationmap from './components/Locationpage/Location';
import Sect2 from './components/section2/sect2';

import Sect4 from './components/Section4/Sect4';
import DetailLocation from './components/Section4/DetailLocation';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import fetchProducts from './redux/Fetchdata';

import Subpage from './components/Section4/Subpage';
import Blogpage from './components/Blog/Blogpage';


const App = () => {
	const [pop, setPop] = useState(false);
	const [value, setvalue] = useState(0);


		const dispatch = useDispatch();
		const database = useSelector((state) => state);
console.log(database)
		useEffect(() => {
			dispatch(fetchProducts());
		}, []);

	// const [database,setDatabase]=useState([]);
	// 	const baseurl = 'https://kalyanihomeadmin.kalyaniaura.com/storage/';

	// 		const fetch=async () => {
	// 			const response=await axios({
	// 				method: 'get',
	// 				url: 'https://kalyanihomeapi.kalyaniaura.com/api/product',
	// 				responseType: 'text/json',
	// 			});
				
	// 				setDatabase(response.data);
	// 				console.log(response.data);
				
	// 		}

	// 		useEffect(()=>{
	// 			fetch()
	// 		},[])

	return (
		<div>
			<Router>
				<Routes>
					<Route
						path="/"
						element={
							<Main
								pop={pop}
								setPop={setPop}
								value={value}
								setvalue={setvalue}
								database={database}
						
							/>
						}
					></Route>
					<Route
						path="/Categories"
						element={
							<Cardsection
								pop={pop}
								setPop={setPop}
								value={value}
								setvalue={setvalue}
							/>
						}
					></Route>
					<Route path="/Map" element={<Locationmap />}></Route>

					<Route path="/Location" element={<Sect4 />}></Route>
					<Route
						path="/Location/DetailLocation"
						element={<DetailLocation />}
					></Route>
					<Route
						path="/Location/DetailLocation/Subpage"
						element={<Subpage />}
					></Route>
					<Route path="/Location/DetailLocation" element={<DetailLocation />}></Route>
					<Route path="/Location/DetailLocation/Subpage" element={<Subpage />}></Route>
					<Route
						path="/Details"
						element={
							<Detail
								pop={pop}
								setPop={setPop}
								value={value}
								setvalue={setvalue}
								database={database}
								
							/>
						}
					></Route>
					<Route path="/Blog/Blogpage" element={<Blogpage />}></Route>

				</Routes>
			</Router>
		</div>
	);
};

export default App;
