import React from 'react';
import Galleryapi from './Galleryapi';
import { useState } from 'react';

const Gallery = ({ pop, setPop, value, setvalue,type,setType }) => {
	const [ext, setExt] = useState(0);
	const [hall, setHall] = useState(0);
	const [bed, setBed] = useState(0);
	const [kitch, setKitch] = useState(0);

	const popfunction=(e)=>{
		setPop(true)
		setType(e.target.getAttribute('type'))
	}

	const exterf = () => {
		if (ext === 2) {
			setExt(0);
		} else {
			setExt(+ext + 1);
		}
	};

	const exterb = () => {
		if (ext === 0) {
			setExt(2);
		} else {
			setExt(+ext - 1);
		}
	};

	const bedf = () => {
		if (bed === 2) {
			setBed(0);
		} else {
			setBed(+bed + 1);
		}
	};

	const bedb = () => {
		if (bed === 0) {
			setBed(2);
		} else {
			setBed(+bed - 1);
		}
	};

	const hallf = () => {
		if (hall === 2) {
			setHall(0);
		} else {
			setHall(+hall + 1);
		}
	};

	const hallb = () => {
		if (hall === 0) {
			setHall(2);
		} else {
			setHall(+hall - 1);
		}
	};

	const kitchf = () => {
		if (kitch === 2) {
			setKitch(0);
		} else {
			setKitch(+kitch + 1);
		}
	};

	const kitchb = () => {
		if (kitch === 0) {
			setKitch(2);
		} else {
			setKitch(+kitch - 1);
		}
	};
	return (
		<div className="section">
			<h1>Gallery/Interiors</h1>
			<div className="gcontainer">
				<div className="fgcontainer">
					<div>
						<div className="gimage">
							<img
								type="exterior"
								src={Galleryapi.exterior[ext]}
								alt=""
								onClick={(e) => {
									popfunction(e);
								}}
							/>
							<h3>Exterior view</h3>
							<i class="fas fa-arrow-left" onClick={exterb}></i>
							<i class="fas fa-arrow-right" onClick={exterf}></i>
						</div>
					</div>

					<div>
						<div className="gimage">
							<img
								type="bedroom"
								src={Galleryapi.bedroom[bed]}
								alt=""
								onClick={(e) => {
									popfunction(e);
								}}
							/>
							<h3>Bedroom</h3>
							<i class="fas fa-arrow-left" onClick={bedb}></i>
							<i class="fas fa-arrow-right" onClick={bedf}></i>
						</div>
					</div>
				</div>
				<div className="sgcontainer">
					<div>
						<div className="gimage">
							<img
								type="hall"
								src={Galleryapi.hall[hall]}
								alt=""
								onClick={(e) => {
									popfunction(e);
								}}
							/>
							<h3>Hall</h3>
							<i class="fas fa-arrow-left" onClick={hallb}></i>
							<i class="fas fa-arrow-right" onClick={hallf}></i>
						</div>
					</div>

					<div>
						<div className="gimage">
							<img
								type="kitchen"
								src={Galleryapi.kitchen[kitch]}
								alt=""
								onClick={(e) => {
									popfunction(e);
								}}
							/>
							<i class="fas fa-arrow-left" onClick={kitchb}></i>
							<i class="fas fa-arrow-right" onClick={kitchf}></i>

							<h3>Kitchen</h3>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Gallery