import React, { useState,useEffect } from 'react';
// import banner from '../images/pexels-curtis-adams-8031899.jpg';
import banner1 from '../images/Student.webp';
import banner2 from '../images/Co-living.webp';
import banner3 from '../images/Thirdimage.webp';
import Navbar from './Navbar';
import secbanner from '../images/HOMEBANNER.jpg';
import secbanner2 from '../images/Homebanner2.jpg'


const Banner = () => {
	const [num,setNum]=useState(1);
	useEffect(() => {
		const interval = setInterval(() => {
			if (num === 2) {
				setNum(1);
			} else {
				setNum(2);
			}
		}, 5000);

		return () => clearInterval(interval);
	});
	return (
		<div>
			<Navbar />
			<div className="banner">
				<div className="bann">
					<div className={num === 1 ? 'section1' : 'section1 active'}>
						<div className="content">
							<h1>
								Isn't it so
								<span> good </span> 
								to be home?
							</h1>
							<h3>We build home that is made of hopes and dreams</h3>
							<button className="btn">Request for site Visit</button>
						</div>

						<div className="back">
					
							<div className="img">
								<img
									src={secbanner}
									alt=""
								/>
							</div>
						</div>
					</div>

					<div className={num === 2 ? 'section1' : 'section1 active'}>
						<div className="content">
							<h1>
								We connect people with a <span>better space</span> for living
							</h1>
							<h3>create your own creative space</h3>
							<button className="btn">Request for site Visit</button>
						</div>

						<div className="back">
							<div className="img">
								<img
									src={secbanner2}
									alt=""
								/>
							</div>
						</div>
					</div>
				</div>

				<div className="section2">
					<div className="cardd">
						<div className="carddd">
							<img src={banner1} alt="" />
							<div className="speci">
								<h1>Commercial space</h1>
								<i class="fas fa-arrow-right"></i>
							</div>
						</div>
					</div>
					<div className="cardd">
						<div className="carddd">
							<img src={banner2} alt="" />
							<div className="speci">
								<h1>Apartments/Villas</h1>
								<i class="fas fa-arrow-right"></i>
							</div>
						</div>
					</div>
					<div className="cardd">
						<div className="carddd">
							{' '}
							<img src={banner3} alt="" />
							<div className="speci">
								<h1>Rentals</h1>
								<i class="fas fa-arrow-right"></i>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Banner;
