export function fetchDataRequest() {
	return {
		type: 'FETCH_DATA_REQUEST',
	};
}

export function fetchDataSuccess(data) {
	return {
		type: 'FETCH_DATA_SUCCESS',
		payload: data,
	};
}

export function fetchDataError(error) {
	return {
		type: 'FETCH_DATA_ERROR',
		payload: { error },
	};
}

export function setproduct(id){
	return{
		type:'PRODUCT_ID',
		payload:id
	}
}