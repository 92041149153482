import React, { useState } from 'react';
import {
	FaVideo,
	FaRegComments,
	FaSearch,
	FaFacebook,
	FaInstagram,
	FaWhatsapp,
	FaGooglePlus,
} from 'react-icons/fa';
import { render } from 'react-dom';
import Calendar from 'react-calendar';
import Navbar from '../common/Navbar'
import Footer from '../common/Footer'
import Blogpopup from './Blogpopup';


const Blogpage = () => {
	const [date, setDate] = useState(new Date());
	const onChange = (date) => {
		setDate(date);
	};
	const [data, setData] = useState([]);
	const [show, setShow]= useState(false);

	const onPopup =()=>{
		if(show===true){
			setShow(false);
		}else{
			setShow(true);
		}
	}
	return (
		<div>
			<Navbar />
			<div className="blog-page-main-container">
				<div className="blog-page-main-section">
					<div className="blog-one-maindiv-container">
						<div className="blog-name-h1-tag-div">
							<h1>BLOG</h1>
						</div>
						<div>
							<div className="blog-date-div-container">
								<div className="date-tag-div">
									<h4>APR</h4>
									<h4>19</h4>
								</div>
								<div className="name-news-head-div">
									<div className="name-newslatter-tag-div-bar">
										<div>
											<h2>Kalyani Homes "Roundup"!</h2>
										</div>
										<div className="ultag-author-div-bar">
											<ul>
												<li>Author: Kalyani</li>
												<li>
													<button className="button-roundup-class">
														<FaVideo className="videoicon" />
														&nbsp;&nbsp;Categories: News, Roundup
													</button>
												</li>
											</ul>
										</div>
									</div>
									<div className="comments-div-bar">
										<FaRegComments className="commentsicon" />
										&nbsp;&nbsp;(<span>{data.length}</span>) Comments
									</div>
								</div>
							</div>
							<div className="iframe-video-div-class-main">
								<div className="iframe-video-div-class">
									<img
										src="https://images.unsplash.com/photo-1600585154340-be6161a56a0c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8aG9tZXN8ZW58MHx8MHx8"
										alt=""
									/>
								</div>
								<div className="text-div-more-container">
									<p>May new home sales gain 2.2% from April</p>
									<p>
										Sales of new single-family houses in May 2015 were at a
										seasonally adjusted annual rate of 546,000, which is up 2.2%
										from April, according to estimates released jointly today by
										the U.S. Census Bureau and the Department of Housing and
										Urban Development. — From Housing Wire
									</p>
									<p>3 ways to tame student loan debt and afford a mortgage</p>
									<p>
										It’s no secret that student loans can make buying a home a
										challenge. But what exactly is the problem, and how can
										buyers overcome it? The problem is that student loans can be
										included in the buyer’s debt-to-income ratio, or DTI. — From
										Bankrate
									</p>
								</div>
								<div className={show === false ? 'popup-page-close' : ''}>
									<Blogpopup setShow={setShow} />
								</div>
								<div className="button-readmore-div-bar">
									<button className="readmore-button" onClick={onPopup}>
										READ MORE
									</button>
								</div>
							</div>
						</div>
						<div>
							<div className="blog-date-div-container">
								<div className="date-tag-div">
									<h4>APR</h4>
									<h4>25</h4>
								</div>
								<div className="name-news-head-div">
									<div className="name-newslatter-tag-div-bar">
										<div>
											<h2>Kalyani Homes "Nayandahalli"!</h2>
										</div>
										<div className="ultag-author-div-bar">
											<ul>
												<li>Author: Kalyani</li>
												<li>
													<button className="button-roundup-class">
														<FaVideo className="videoicon" />
														&nbsp;&nbsp;Categories: News, Roundup
													</button>
												</li>
											</ul>
										</div>
									</div>
									<div className="comments-div-bar">
										<FaRegComments className="commentsicon" />
										&nbsp;&nbsp;(<span>{data.length}</span>) Comments
									</div>
								</div>
							</div>
							<div className="iframe-video-div-class-main">
								<div className="iframe-video-div-class">
									<img
										src="https://images.unsplash.com/photo-1600585154340-be6161a56a0c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8aG9tZXN8ZW58MHx8MHx8"
										alt=""
									/>
								</div>
								<div className="text-div-more-container">
									<p>May new home sales gain 2.2% from April</p>
									<p>
										Sales of new single-family houses in May 2015 were at a
										seasonally adjusted annual rate of 546,000, which is up 2.2%
										from April, according to estimates released jointly today by
										the U.S. Census Bureau and the Department of Housing and
										Urban Development. — From Housing Wire
									</p>
									<p>3 ways to tame student loan debt and afford a mortgage</p>
									<p>
										It’s no secret that student loans can make buying a home a
										challenge. But what exactly is the problem, and how can
										buyers overcome it? The problem is that student loans can be
										included in the buyer’s debt-to-income ratio, or DTI. — From
										Bankrate
									</p>
								</div>
								<div className="button-readmore-div-bar">
									<button className="readmore-button" onClick={onPopup}>
										READ MORE
									</button>
								</div>
							</div>
						</div>
					</div>

					<div className="blog-two-maindiv-container">
						<div className="search-name-h1-tag-div">
							<h2>SEARCH BLOG</h2>
						</div>
						<div className="text-search-bar-div-bar">
							<input type="text" placeholder="Search here" />
							&nbsp;&nbsp;
							<FaSearch className="searchicon" />
						</div>
						<div className="media-name-h1-tag-div">
							<h2>SOCIAL MEDIA</h2>
							<div className="icon-media-div-bar">
								<ul>
									<li>
										<button className="social-icon">
											<FaFacebook className="fbicon" />
										</button>
									</li>
									<li>
										<button className="social-icon">
											<FaInstagram className="insticon" />
										</button>
									</li>
									<li>
										<button className="social-icon">
											<FaWhatsapp className="whaticon" />
										</button>
									</li>
									<li>
										<button className="social-icon">
											<FaGooglePlus className="googleicon" />
										</button>
									</li>
								</ul>
							</div>
						</div>
						<div className="">
							<div className="post-name-h1-tag-div">
								<h2>LATEST POST</h2>
							</div>
							<div className="post-news-div-container">
								<h3>Real Estate “Roundup”!</h3>
								<h5>TUE - 19 APR - 10:39 AM</h5>
								<p>
									May new home sales gain 2.2% from April Sales of new
									single-family houses in May 2015 were at a seasonally…
								</p>
								<button className="read-button-class-bar">READ MORE</button>
							</div>
							<div className="post-news-div-container">
								<h3>Real Estate “Roundup”!</h3>
								<h5>TUE - 19 APR - 10:39 AM</h5>
								<p>
									May new home sales gain 2.2% from April Sales of new
									single-family houses in May 2015 were at a seasonally…
								</p>
								<button className="read-button-class-bar">READ MORE</button>
							</div>
							<div className="post-news-div-container">
								<h3>Real Estate “Roundup”!</h3>
								<h5>TUE - 19 APR - 10:39 AM</h5>
								<p>
									May new home sales gain 2.2% from April Sales of new
									single-family houses in May 2015 were at a seasonally…
								</p>
								<button className="read-button-class-bar">READ MORE</button>
							</div>
							<div className="post-news-div-container">
								<h3>Real Estate “Roundup”!</h3>
								<h5>TUE - 19 APR - 10:39 AM</h5>
								<p>
									May new home sales gain 2.2% from April Sales of new
									single-family houses in May 2015 were at a seasonally…
								</p>
								<button className="read-button-class-bar">READ MORE</button>
							</div>
						</div>
						<div className="calendar-div-container">
							<div className="post-name-h1-tag-div">
								<h2>CALENDAR</h2>
							</div>
							<div className="date-calender-div-bar">
								<Calendar onChange={onChange} value={date} />
								{console.log(date)}
								{/* {date.toString()} */}
							</div>
						</div>
						<div className="categories-div-container">
							<div className="post-name-h1-tag-div">
								<h2>CATEGORIES</h2>
							</div>
							<div className="categories-div-row-bar">
								<ul>
									<li>1.News(1)</li>
									<li>2.Roundup(1)</li>
									<li>3.Uncategorized(2)</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			
			<div>
				<div className="card-slide-blogpage">
					<div className="card-slide-section">
						<h1>MORE BLOGS</h1>
						<section>
							<div className="card-section-more-page">
								<h4>Kalyani Homes "Nayandahalli"!</h4>
								<div className="img-section-more-blog">
									<img
										src="https://images.pexels.com/photos/269077/pexels-photo-269077.jpeg?auto=compress&cs=tinysrgb&"
										alt=""
									/>
									<img
										src="https://images.pexels.com/photos/269077/pexels-photo-269077.jpeg?auto=compress&cs=tinysrgb&"
										alt=""
									/>
									<img
										src="https://images.pexels.com/photos/269077/pexels-photo-269077.jpeg?auto=compress&cs=tinysrgb&"
										alt=""
									/>
								</div>
								<p>
									Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam
									nesciunt saepe magni laborum numquam consequatur
								</p>

								<button className="more-blog-button">READ MORE</button>
							</div>
							<div className="card-section-more-page">
								<h4>Kalyani Homes "Nayandahalli"!</h4>
								<div className="img-section-more-blog">
									<img
										src="https://images.pexels.com/photos/269077/pexels-photo-269077.jpeg?auto=compress&cs=tinysrgb&"
										alt=""
									/>
									<img
										src="https://images.pexels.com/photos/269077/pexels-photo-269077.jpeg?auto=compress&cs=tinysrgb&"
										alt=""
									/>
									<img
										src="https://images.pexels.com/photos/269077/pexels-photo-269077.jpeg?auto=compress&cs=tinysrgb&"
										alt=""
									/>
								</div>
								<p>
									Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam
									nesciunt saepe magni laborum numquam consequatur
								</p>

								<button className="more-blog-button">READ MORE</button>
							</div>
							<div className="card-section-more-page">
								<h4>Kalyani Homes "Nayandahalli"!</h4>
								<div className="img-section-more-blog">
									<img
										src="https://images.pexels.com/photos/269077/pexels-photo-269077.jpeg?auto=compress&cs=tinysrgb&"
										alt=""
									/>
									<img
										src="https://images.pexels.com/photos/269077/pexels-photo-269077.jpeg?auto=compress&cs=tinysrgb&"
										alt=""
									/>
									<img
										src="https://images.pexels.com/photos/269077/pexels-photo-269077.jpeg?auto=compress&cs=tinysrgb&"
										alt=""
									/>
								</div>
								<p>
									Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam
									nesciunt saepe magni laborum numquam consequatur
								</p>

								<button className="more-blog-button">READ MORE</button>
							</div>
							<div className="card-section-more-page">
								<h4>Kalyani Homes "Nayandahalli"!</h4>
								<div className="img-section-more-blog">
									<img
										src="https://images.pexels.com/photos/269077/pexels-photo-269077.jpeg?auto=compress&cs=tinysrgb&"
										alt=""
									/>
									<img
										src="https://images.pexels.com/photos/269077/pexels-photo-269077.jpeg?auto=compress&cs=tinysrgb&"
										alt=""
									/>
									<img
										src="https://images.pexels.com/photos/269077/pexels-photo-269077.jpeg?auto=compress&cs=tinysrgb&"
										alt=""
									/>
								</div>
								<p>
									Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam
									nesciunt saepe magni laborum numquam consequatur
								</p>

								<button className="more-blog-button">READ MORE</button>
							</div>
							<div className="card-section-more-page">
								<h4>Kalyani Homes "Nayandahalli"!</h4>
								<div className="img-section-more-blog">
									<img
										src="https://images.pexels.com/photos/269077/pexels-photo-269077.jpeg?auto=compress&cs=tinysrgb&"
										alt=""
									/>
									<img
										src="https://images.pexels.com/photos/269077/pexels-photo-269077.jpeg?auto=compress&cs=tinysrgb&"
										alt=""
									/>
									<img
										src="https://images.pexels.com/photos/269077/pexels-photo-269077.jpeg?auto=compress&cs=tinysrgb&"
										alt=""
									/>
								</div>
								<p>
									Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam
									nesciunt saepe magni laborum numquam consequatur
								</p>

								<button className="more-blog-button">READ MORE</button>
							</div>
							<div className="card-section-more-page">
								<h4>Kalyani Homes "Nayandahalli"!</h4>
								<div className="img-section-more-blog">
									<img
										src="https://images.pexels.com/photos/269077/pexels-photo-269077.jpeg?auto=compress&cs=tinysrgb&"
										alt=""
									/>
									<img
										src="https://images.pexels.com/photos/269077/pexels-photo-269077.jpeg?auto=compress&cs=tinysrgb&"
										alt=""
									/>
									<img
										src="https://images.pexels.com/photos/269077/pexels-photo-269077.jpeg?auto=compress&cs=tinysrgb&"
										alt=""
									/>
								</div>
								<p>
									Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam
									nesciunt saepe magni laborum numquam consequatur
								</p>

								<button className="more-blog-button">READ MORE</button>
							</div>
							{/* <div className="card-section-more-page">
								<h4>Kalyani Homes "Nayandahalli"!</h4>
								<div className="img-section-more-blog">
									<img
										src="https://images.pexels.com/photos/269077/pexels-photo-269077.jpeg?auto=compress&cs=tinysrgb&"
										alt=""
									/>
									<img
										src="https://images.pexels.com/photos/269077/pexels-photo-269077.jpeg?auto=compress&cs=tinysrgb&"
										alt=""
									/>
									<img
										src="https://images.pexels.com/photos/269077/pexels-photo-269077.jpeg?auto=compress&cs=tinysrgb&"
										alt=""
									/>
								</div>
								<p>
									Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam
									nesciunt saepe magni laborum numquam consequatur
								</p>

								<button className="more-blog-button">READ MORE</button>
							</div> */}
						</section>
					</div>
				</div>
			</div>
			</div>
			<Footer />
		</div>
	);
}

export default Blogpage;