import React from 'react';
import warranty from '../images/warrantywhite.svg';
import estate from '../images/realestate2.png';
import techpark from '../images/techparkwhite.svg';
// import reliable from '../images/reliablehomes2.png';
import reliable from '../images/relaible homes white-01.svg';
import realestate from '../images/realestateimage.svg';

const about = () => {
	return (
		<div>
			<section className="about" id="about">
				<h1>Why Kalyani Homes?</h1>

				<div className="about_sect1">
					<div className="about_cont">
						<h3>Kalyani Apartments</h3>
						<h1>ABOUT OUR COMPANY</h1>
						<p>
							Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptate
							corporis quidem deleniti unde vel nostrum, reiciendis nobis
							voluptatem soluta, reprehenderit nulla, quae aperiam hic qui
							corrupti illo! Nisi nihil sed quos nemo, quidem, fugiat explicabo
							earum aut, facere quaerat voluptatum sint doloribus ipsum est
							tenetur sunt quisquam rem nulla eum.
						</p>
						<span>Read more...</span>
					</div>
					<div className="about_img">
						<img
							src="https://images.pexels.com/photos/162539/architecture-building-amsterdam-blue-sky-162539.jpeg?auto=compress&cs=tinysrgb&w=600"
							alt=""
						/>
					</div>
				</div>

				<div className="contain">
					<div className="boxes">
						<div className="cards">
							<img src={realestate} alt="" />
						</div>
						<h5>Real Estate</h5>
					</div>
					<div className="boxes">
						<div className="cards">
							<img src={reliable} alt="" />
						</div>
						<h5>Reliable Homes</h5>
					</div>
					<div className="boxes">
						<div className="cards">
							<img src={techpark} alt="" />
						</div>
						<h5>Techpark</h5>
					</div>
					<div className="boxes">
						<div className="cards">
							<img src={warranty} alt="" />
						</div>
						<h5>Warranty</h5>
					</div>
				</div>
			</section>
		</div>
	);
};

export default about;
