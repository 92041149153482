import React from 'react';
import { AiOutlineMail } from 'react-icons/ai';
import {
	FaFacebook,
	FaInstagram,
	FaTwitter,
	FaWhatsapp,
	FaPinterest,
} from 'react-icons/fa';
import { GoMail } from 'react-icons/go';
import image from '../Section4/NEWSLETTER ICON-01-02.jpg';

const Secpage = () => {
	return (
		<div className="Section-page-container">
			<div className="page-div-section">
				<div className="two-div-bar">
					<div className="img-div-bar">
						<img
							className="img-part-img"
							src="https://img.freepik.com/free-photo/glassclad-skyscrapers-central-mumbai-reflecting-sunset-hues-blue-hour_469504-15.jpg?w=740&t=st=1661770059~exp=1661770659~hmac=6b47a54178d96194817ddf09720581bc27851df964df86767bcdc8efe7f8b2f2"
							alt=""
						/>
					</div>
					<div className="form-conatiner">
						<div className="text-div-bar">
							<div className="mailbox-icon">
								<GoMail className="mail-box" />
							</div>
							<div className="h1-div-bar">
								<h1>Join us !</h1>
							</div>
							<div className="update-div-bar">
								<h3>Subscibe to get Updates</h3>
							</div>
							<div className="input-div-bar">
								<AiOutlineMail className="icon-tab" />
								&nbsp;&nbsp;&nbsp;&nbsp;
								<input
									className="input-place-bar"
									type="email"
									placeholder="Enter your Email id"
								/>
							</div>
							<div className="button-div-subscribe">
								<button className="button-button-tag">Subscribe</button>
							</div>
						</div>
					</div>
					<div className="media-icon-conatiner">
						<div className="icon-sub-container">
							<div className="icon-div-bar">
								<div className="fb-section">
									<FaFacebook />
								</div>
								<div className="ist-section">
									<FaInstagram />
								</div>
								<div className="tw-section">
									<FaTwitter />
								</div>
								<div className="wp-section">
									<FaWhatsapp />
								</div>
								<div className="pn-section">
									<FaPinterest />
								</div>
							</div>
						</div>
					</div>
					
				</div>
			</div>
		</div>
	);
};

export default Secpage;
