import React from 'react';
import image from '../images/secimage.png'

const Sect = () => {
	return (
		<div>
			<section>
				<div className="cards_sec">
					<div className="sec_cont">
						<h1>Kalyanis Constructions</h1>
						<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatem excepturi ratione atque, impedit alias molestias aliquid odit placeat voluptatum enim.</p>
						<button className=''>Explore</button>
					</div>
					<div className="sec_img">
						<img src={image} alt="" />
					</div>
				</div>
			</section>
		</div>
	)
}

export default Sect;