import React from 'react';


const Clients = () => {
	return (
		<div className="clients">
			{/* <h1>Our Happy Clients</h1> */}
			<div className="clients_container">
				<div className="client">
					<div className="p_image">
						<img
							src="https://kalyaniaura.com/wp-content/uploads/2021/02/talend.png"
							alt=""
						/>
					</div>
				</div>
				<div className="client">
					<div className="p_image">
						<img
							src="https://kalyaniaura.com/wp-content/uploads/2021/02/new-Oracle-Logo.png"
							alt=""
						/>
					</div>
				</div>
				<div className="client">
					<div className="p_image">
						<img
							src="	https://kalyaniaura.com/wp-content/uploads/2021/02/spo-delphi-Logo-300x111.png"
							alt=""
						/>
					</div>
				</div>
				<div className="client">
					<div className="p_image">
						<img
							src="https://kalyaniaura.com/wp-content/uploads/2021/02/aptiv_logo-300x68.png"
							alt=""
						/>
					</div>
				</div>
				<div className="client">
					<div className="p_image">
						<img
							src="	https://kalyaniaura.com/wp-content/uploads/2021/02/future-electronic-300x150.jpg"
							alt=""
						/>
					</div>
				</div>
				<div className="client">
					<div className="p_image">
						<img
							src="https://kalyaniaura.com/wp-content/uploads/2021/02/koch-industries.jpg"
							alt=""
						/>
					</div>
				</div>
				<div className="client">
					<div className="p_image">
						<img
							src="https://kalyaniaura.com/wp-content/uploads/2021/02/nttdata-300x58.png"
							alt=""
						/>
					</div>
				</div>

				<div className="client">
					<div className="p_image">
						<img
							src="https://kalyaniaura.com/wp-content/uploads/2021/02/vmware.png"
							alt=""
						/>
					</div>
				</div>
				<div className="client">
					<div className="p_image">
						<img
							src="https://kalyaniaura.com/wp-content/uploads/2021/02/borgwarner-300x50.jpg"
							alt=""
						/>
					</div>
				</div>
				<div className="client">
					<div className="p_image">
						<img
							src="	https://kalyaniaura.com/wp-content/uploads/2021/02/spo-accenture-logo-300x87.png"
							alt=""
						/>
					</div>
				</div>
				<div className="client">
					<div className="p_image">
						<img
							src="https://kalyaniaura.com/wp-content/uploads/2021/02/spo-hpe-logo-300x125.png"
							alt=""
						/>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Clients