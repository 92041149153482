const Floor = [
	{
		id: 3,
		title: '3BHK MasterPlan',
		src: 'https://i.pinimg.com/736x/6d/73/7b/6d737bcd224c41adbd832785d145c53d.jpg',
		type: '3BHK',
	},
	{
		id: 3,
		title: '3BHK MasterPlan',
		src: 'https://i0.wp.com/civiconcepts.com/wp-content/uploads/2021/10/modern-3-bedroom-house-plans.jpg',
		type: '3BHK',
	},
	{
		id: 3,
		title: '3BHK MasterPlan',
		src: 'https://1.bp.blogspot.com/-ij1vI4tHca0/XejniNOFFKI/AAAAAAAAAMY/kVEhyEYMvXwuhF09qQv1q0gjqcwknO7KwCEwYBhgL/s1600/3-BHK-single-Floor-1188-Sq.ft.png',
		type: '3BHK',
	},
	{
		id: 3,
		title: '3BHK MasterPlan',
		src: 'https://i0.wp.com/civiconcepts.com/wp-content/uploads/2021/10/3-bedroom-floor-plans.jpg?resize=464%2C724&ssl=1',
		type: '3BHK',
	},
	{
		id: 3,
		title: '3BHK MasterPlan',
		src: 'https://2dhouseplan.com/wp-content/uploads/2021/10/Simple-Low-Budget-Modern-3-Bedroom-House-Design-681x1024.jpg',
		type: '3BHK',
	},
	{
		id: 2,
		title: '2BHK MasterPlan',
		src: 'https://i.pinimg.com/736x/9f/d5/3c/9fd53c59f4f8134ad72fbe0b5922130b.jpg',
		type: '2BHK',
	},
	{
		id: 2,
		title: '2BHK MasterPlan',
		src: 'https://i.pinimg.com/originals/98/c5/b4/98c5b4883dafb44bc39abcac54a01b63.gif',
		type: '2BHK',
	},
	{
		id: 2,
		title: '2BHK MasterPlan',
		src: 'https://images.familyhomeplans.com/plans/80518/80518-1l.gif',
		type: '2BHK',
	},
	{
		id: 2,
		title: '2BHK MasterPlan',
		src: 'https://ulrichome.com/wp-content/uploads/2018/03/SHD-20120001-FOR-POST-Model.jpg?189db0&189db0',
		type: '2BHK',
	},
	{
		id: 2,
		title: '2BHK MasterPlan',
		src: 'https://ulrichome.com/wp-content/uploads/2018/03/SHD-20120001-FOR-POST-Model.jpg?189db0&189db0',
		type: '2BHK',
	},
	{
		id: 4,
		title: 'MasterPlan',
		src: 'https://www.researchgate.net/profile/Oliver-Penny/publication/340926474/figure/fig42/AS:884351344578575@1587857199789/Design-C-conceptual-master-plan.png',
		type: 'master',
	},
	{
		id: 4,
		title: 'MasterPlan',
		src: 'https://www.prestigeprimerosehills.ind.in/images/plans/thumbnail/master-plan.jpg',
		type: 'master',
	},
	{
		id: 4,
		title: 'MasterPlan',
		src: 'https://mir-s3-cdn-cf.behance.net/project_modules/fs/8e50b960694255.5a5624ef80a2a.jpg',
		type: 'master',
	},
	{
		id: 5,
		title: 'Route Map',
		src: 'http://www.eciecc.com/flash/ROUTEMAP.jpg',
		type: 'route',
	},
	{
		id: 5,
		title: 'Route Map',
		src: 'http://www.yukenindia.com/wp-content/uploads/2016/09/Bangalore-Map.jpg',
		type: 'route',
	},
	{
		id: 5,
		title: 'Route Map',
		src: 'https://fiverr-res.cloudinary.com/images/q_auto,f_auto/gigs/100151425/original/cb69b72333dc90d03fe3227ba3fa7786a840bb20/create-a-route-map.jpg',
		type: 'route',
	},
];
export default Floor;




  // const Floor = 
	// 	{
	// 		id: 1,
	// 		title: 'This is Two BHK ',
	// 		url: 'https://picsum.photos/',
	// 		type: '2bhk'
	// 	},
	// 	{
	// 		id: 2,
	// 		title: 'This is Two BHK',
	// 		url: 'https://picsum.photos/',
	// 		type: '2bhk'
	// 	},
	// 	{
	// 		id: 3,
	// 		title: 'This is Two BHK',
	// 		url: 'https://picsum.photos/',
	// 		type: '2bhk'
	// 	},
	// 	{
	// 		id: 4,
	// 		title: 'This is Two BHK',
	// 		url: 'https://picsum.photos/',
	// 		type: '2bhk'
	// 	},
	// 	{
	// 		id: 5,
	// 		title: 'This is Two BHK',
	// 		url: 'https://picsum.photos/',
	// 		type: '2bhk'
	// 	},
	// 	{
	// 		id: 6,
	// 		title: 'This is Three BHK',
	// 		url: 'https://picsum.photos/',
	// 		type: '3bhk'
	// 	},
	// 	{
	// 		id: 7,
	// 		title: 'This is Three BHK',
	// 		url: 'https://picsum.photos/',
	// 		type: '3bhk'
	// 	},
	// 	{
	// 		id: 8,
	// 		title: 'This is Three BHK',
	// 		url: 'https://picsum.photos/',
	// 		type: '3bhk'
	// 	},
	// 	{
	// 		id: 9,
	// 		title: 'This is Three BHK',
	// 		url: 'https://picsum.photos/',
	// 		type: 'master'
	// 	},
	// 	{
	// 		id: 9,
	// 		title: 'This is Three BHK',
	// 		url: 'https://picsum.photos/',
	// 		type: 'master'
	// 	}
	//

  // export default Floor;
