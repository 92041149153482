import React from 'react';
// import Popup from './components/Popup';
// import Navbar from './components/common/Navbar';
import Home from './components/main/Home';
import About from './components/main/About';
// import Slider from './components/slider';



// import Section from './components/Section';
import Steps from './components/main/Steps';
import Slider from './components/main/Slider';
import './css/section2/Cards.css';
import Dslider from './components/main/Dslider';
import Sslider from './components/main/Sslider';
import Contactsection from './components/main/Contactsection';
import Footer from './components/common/Footer';
import Banner from './components/common/Banner';
import Questions from './components/main/Questions';
import Clients from './components/main/Clients';
import { useSelector } from 'react-redux';

const Main = () => {
	 const database=useSelector(state=>state)
	

	return (
		<div>
			{/* <Navbar /> */}
			<Banner style={{ position: 'relative' }} />
			{/* <Home /> */}
			<About />

			{/* {pop === true ? (
				<Popup pop={pop} setPop={setPop} setvalue={setvalue} value={value} />
			) : (
				<Slider setPop={setPop} setvalue={setvalue} value={value} />
			)} */}
			{/* <Section /> */}

			{/* classname slide */}

			<div className="hslide">
				<div className="hsection">
					<Sslider />
					<div className="hcont">
						<h1>Our New Launches</h1>
						<p>
							Our newly launched homes are inspired by modern and contemporary
							design and designed just the way you want. Fully furnished and
							ready to move in homes.
						</p>
						<button className="btn">Buy Now</button>
					</div>
				</div>

				<div className="h1section">
					<div className="hcont">
						<h1>Our Team</h1>
						<p>
							Our team helps you customize your home with our design expertise,
							we design together with you to accomplish your dream home.
						</p>
						<button className="btn">Buy Now</button>
					</div>
					<Dslider />
				</div>
			</div>
			{database === undefined ? (
				<h1>loading</h1>
			) : (
				<Slider database={database.Products}  />
			)}
			{/*
			 */}
			<Steps />
			<Contactsection />
			<Clients />
			<Questions />
			<Footer />
		</div>
	);
};

export default Main;
