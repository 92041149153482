import React, { useState } from 'react';
import { useEffect } from 'react';
import img1 from '../images/stepsimg.png';
import { FaSearch, FaUserTie, FaHome ,FaHandshake} from 'react-icons/fa';

const Steps = () => {
	let [num, setNum] = useState(0);

	useEffect(() => {
		const interval = setInterval(() => {
			if (num === 3) {
				setNum(0);
			} else {
				setNum(+num + 1);
			}
		}, 4000);

		return () => clearInterval(interval);
	});

	const arr = [
		{
			heading: 'Find A Property',
			title:
				'Shortlist the property and visit the site.',
		},
		{
			heading: 'Meet Our Agent',
			title:
				'Our agent will assist you at every stage and closes the deal faster.',
		},
		{
			heading: 'Legal Assistance',
			title:
				'Get your legal assistance done with the help of our agent.',
		},
		{
			heading: 'Have Your Dream Home',
			title:
				'Our agents and builders will help you building your dream home.',
		},
	];
	
	return (
		<div className="ste_ps">
			<h1>How it works?</h1>
			<div className="step">
				<div className="steps">
					<div className={num === 0 ? 'circle active' : 'circle'}>1</div>
					<div>--------</div>
					<div className={num === 1 ? 'circle active' : 'circle'}>2</div>
					<div>--------</div>
					<div className={num === 2 ? 'circle active' : 'circle'}>3</div>
					<div>--------</div>
					<div className={num === 3 ? 'circle active' : 'circle'}>4</div>
				</div>
				<div className="steps_cards">
					<div className={num === 0 ? 'step_card active' : 'step_card'}>
						<i>
							{' '}
							<FaSearch />
						</i>

						<h1>{arr[0].heading}</h1>
						<p>{arr[0].title}</p>
					</div>
					<div className={num === 1 ? 'step_card active' : 'step_card'}>
						<i>
							<FaUserTie />
						</i>

						<h1>{arr[1].heading}</h1>
						<p>{arr[1].title}</p>
					</div>
					<div className={num === 2 ? 'step_card active' : 'step_card'}>
						<i>
							{' '}
							<FaHandshake />
						</i>

						<h1>{arr[2].heading}</h1>
						<p>{arr[2].title}</p>
					</div>
					<div className={num === 3 ? 'step_card active' : 'step_card'}>
						<i>
							{' '}
							<FaHome />
						</i>

						<h1>{arr[3].heading}</h1>
						<p>{arr[3].title}</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Steps;
