import { FaBed } from 'react-icons/fa';
import Data from '../main/api';
import Navbar from '../common/Navbar';
import Footer from '../common/Footer';
import { useNavigate } from 'react-router-dom';
import Sect from './Sect';
import React, { useEffect } from 'react';
import Bannercarousel from './carousel'
import Banner from '../common/Banner';
import Filter from './filter';





function Cardsection({pop,setPop,value,setvalue}) {
	 let navigate = useNavigate();
	

	const popfunc = () => {
		navigate('../Details');
	};

	
	








	return (
		<div className="cardsection">
			<Navbar />
			<div>
				<Bannercarousel />
				<Filter />
				<Sect />

				<div className="cardcontainer">
					{Data.map((v) => {
						return (
							<div className="box">
								<div className="card" onClick={popfunc}>
									<div className="tag">new launch</div>
									<img src={v.src} alt="" />
									<div className="cardcontent">
										<div className="heading">
											<h3 style={{ fontSize: '15px' }}>
												Kalyani Park @ Nayandhalli
											</h3>
											<h4
												style={{
													fontSize: '13px',
													fontWeight: 200,
												}}
											>
												Nayandahalli Bangalore
											</h4>
										</div>
										<div>
											<div className="rate">
												<ul>
													<li>
														<FaBed />
													</li>
													<li>1,2,3</li>
												</ul>
												<button>60* lacs onwords</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						);
					})}
				</div>
			</div>
			<div className="fsection">
				<Footer />
			</div>
		</div>
	);
}

export default Cardsection;
