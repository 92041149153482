import React from 'react'
import { useState } from 'react';
import Apilocationimg from './Apilocationimg'
import {
	BsFillArrowLeftCircleFill,
	BsFillArrowRightCircleFill,
} from 'react-icons/bs';
import DetailsSecond from './DetailsSecond';
import Navbar from '../common/Navbar';
import Footer from '../common/Footer';
import { useNavigate } from 'react-router-dom';


const DetailLocation = () => {
	const [sub, setSub] =useState(Apilocationimg);
	const [section, setSection] =useState(0)
	const move = +sub.length- 1;
	console.log(section);

	let navigate = useNavigate();
	const leftbuttonlocation =()=>{
		if(section === 0){
			setSection(move);
		}else{
			setSection(+section-1);
		}
	}; 
	const rightbuttonlocation=()=>{
		if(section===move){
			setSection(0);
		}else{
			setSection(+section+1);
		}
	};
  const moveDot = (index) => {
	setSection(index);
  };

	const handlesubpage = () => {
		navigate('./Subpage');
	};

	return (
		<div className="sub-location-page-details">
			<Navbar />
			<div className="sub-location-section">
				<div className="sub-location-first-div">
					<div className="first-bar-cont-one">
						<div className="img-first-bar">
							<img src="https://teja8.kuikr.com/cfassets/images/buy-apartments.webp" />
						</div>
						<div className="text-first-bar">
							<h3>Buy - Apartments</h3>
							<p>Below -&#2352;60 Lakhs</p>
							<p>&#2352;60 Lakhs - &#2352;80 Lakhs</p>
							<p>&#2352;80 Lakhs - &#2352;1 Crore</p>
						</div>
					</div>
					<div className="first-bar-cont-one">
						<div className="img-first-bar">
							<img src="https://teja8.kuikr.com/cfassets/images/buy-villas.webp" />
						</div>
						<div className="text-first-bar">
							<h3>Buy - Villas</h3>
							<p>&#2352;60 Lakhs -&#2352;80 Lakhs</p>
							<p>&#2352;80 Lakhs - &#2352;1 Crore</p>
							<p>&#2352;1 Crore - &#2352;5 Crore</p>
						</div>
					</div>
					<div className="first-bar-cont-one">
						<div className="img-first-bar">
							<img src="https://teja8.kuikr.com/cfassets/images/buy-plots.webp" />
						</div>
						<div className="text-first-bar">
							<h3>Buy - Plots</h3>
							<p>Below -&#2352;60 Lakhs</p>
							<p>&#2352;60 Lakhs - &#2352;80 Lakhs</p>
							<p>Above - &#2352;1 Crore</p>
						</div>
					</div>
					<div className="first-bar-cont-one">
						<div className="img-first-bar">
							<img src="https://teja8.kuikr.com/cfassets/images/rent-apartments.webp" />
						</div>
						<div className="text-first-bar">
							<h3>Rent - Apartments</h3>
							<p>1 - BHK</p>
							<p>2 - BHK</p>
							<p>3 - BHK</p>
						</div>
					</div>
				</div>
				<div className="sub-location-second-div">
					<div className="button-location-bar">
						<button className="button-item-location">
							<h3>SARJAPURA ROAD</h3>
							<p>840+ for Sale & 20+ for Rent</p>
						</button>
					</div>
					<div className="button-location-bar">
						<button className="button-item-location">
							<h3>WHITEFIELD</h3>
							<p>840+ for Sale & 20+ for Rent</p>
						</button>
					</div>
					<div className="button-location-bar">
						<button className="button-item-location">
							<h3>DEVANAHALLI</h3>
							<p>840+ for Sale & 20+ for Rent</p>
						</button>
					</div>
					<div className="button-location-bar">
						<button className="button-item-location">
							<h3>SARJAPUR</h3>
							<p>840+ for Sale & 20+ for Rent</p>
						</button>
					</div>
					<div className="button-location-bar">
						<button className="button-item-location">
							<h3>YELAHANKA</h3>
							<p>840+ for Sale & 20+ for Rent</p>
						</button>
					</div>
					<div className="button-location-bar">
						<button className="button-item-location">
							<h3>NAYANDAHALLI</h3>
							<p>840+ for Sale & 20+ for Rent</p>
						</button>
					</div>
				</div>
				<div className="single-slide-location-three-div">
					<div className="single-slide-div-bar">
						<div className="slider-first-section-content">
							<button
								className="left-button-location"
								onClick={leftbuttonlocation}
							>
								<BsFillArrowLeftCircleFill />
							</button>
							<div className="text-slider-first-section-row">
								<div className="inside-row-first">
									<h7>USP</h7>
									<p>Lorem ipsum dolor sit </p>
								</div>
								<div className="inside-row-second">
									<h3>{sub[section].title}</h3>
									<p>
										Lorem ipsum dolor sit amet
										<br /> consectetur adipisicing
									</p>
									<h3>{sub[section].price}</h3>
								</div>
								<div className="inside-rwo-three">
									<button className="button-expore-slider">EXPLORE NOW</button>
								</div>
							</div>
							<div className="image-slider-section-row">
								<img src={sub[section].src} alt="" onClick={handlesubpage} />
							</div>
							<button
								className="right-button-location"
								onClick={rightbuttonlocation}
							>
								<BsFillArrowRightCircleFill />
							</button>
						</div>
						<div className="slide-dots-location-div">
							{Array.from({ length: 8 }).map((item, index) => (
								<div
									onClick={() => moveDot(index + 0)}
									className={
										section === index + 0
											? 'card-dot-location active'
											: 'card-dot-location'
									}
								></div>
							))}
						</div>
					</div>
				</div>
				<div className="">
					<DetailsSecond />
				</div>
			</div>
			<Footer />
		</div>
	);
}

export default DetailLocation;