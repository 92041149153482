const data = [
	{
		id: 1,
		name: 'Lisbon House',
		i_d: 'Lisbonhouse',
		Address: 'Electronic City Phase 1',
		location_src:
			'https://maps.google.com/maps?q=27.2046,77.4977&hl=es&z=14&amp;output=embed',
		price: '9,399',
		location:
			'https://www.google.com/maps/d/embed?mid=1FPyfl0fhpO7s5c-ocKBNWgpn2L7ZVlw&ehbc=2E312F',
		latitude: '27.2046',
		longitude: '77.4977',
	},

	{
		id: 2,
		name: 'Frankfurt House',
		i_d: 'Frankfurthouse',
		Address: 'Kormangala',
		location_src:
			'https://maps.google.com/maps?q=12.2958,76.6394&hl=es&z=14&amp;output=embed',
		price: '10,299',
		location:
			'https://www.google.com/maps/d/embed?mid=1u-zcxYPkzxaEUuLhEEJLbkSKTYofAwQ&ehbc=2E312F',
		latitude: '12.2958',
		longitude: '76.6394',
	},

	{
		id: 3,
		name: 'Amsterdam House',
		i_d: 'amsterdamhouse',
		Address: 'Electronic City Phase 1',
		location_src:
			'https://maps.google.com/maps?q=27.2046,77.4977&hl=es&z=14&amp;output=embed',

		price: '11,399',
		location:
			'https://www.google.com/maps/d/embed?mid=1MxAIFRJyZawQR6r7ipLFh58RhE0x0oA&ehbc=2E312F',
		latitude: '27.2046',
		longitude: '77.4977',
	},
	{
		id: 4,
		name: 'Denver House',
		i_d: 'Denverhouse',
		Address: 'Bannerghatta Road',
		location_src:
			'https://maps.google.com/maps?q=14.4644,75.9218&hl=es&z=14&amp;output=embed',

		price: '9,859',
		location:
			'https://www.google.com/maps/d/embed?mid=1hxjkeNSkP29AQ9O5JqxMkeDWDFbylgg&ehbc=2E312F',
		latitude: '14.4644',
		longitude: '75.9218',
	},
	{
		id: 5,
		name: 'Swansea House',
		i_d: 'Swanseahouse',
		Address: 'Electronic City Phase 2',
		location_src:
			'https://maps.google.com/maps?q=27.2046,77.4977&hl=es&z=14&amp;output=embed',
		price: '9,399',
		location:
			'https://www.google.com/maps/d/embed?mid=132rrleoe-78maArVq-iXXi-z_KwHVhw&ehbc=2E312F',
		latitude: '27.2046',
		longitude: '77.4977',
	},
	{
		id: 6,
		name: 'Sofia House',
		i_d: 'Sofiahouse',
		Address: 'sarjapur Road',
		location_src:
			'https://maps.google.com/maps?q=12.9141,74.8560&hl=es&z=14&amp;output=embed',
		price: '8.899',
		location:
			'https://www.google.com/maps/d/embed?mid=1MxAIFRJyZawQR6r7ipLFh58RhE0x0oA&ehbc=2E312F',
		latitude: '27.2046',
		longitude: '77.4977',
	},
	{
		id: 7,
		name: 'Turin House',
		i_d: 'Turinhouse',
		Address: 'Electronic City Phase 1',
		location_src:
			'https://maps.google.com/maps?q=15.4589,75.0078&hl=es&z=14&amp;output=embed',
		price: '9,399',
		location:
			'https://www.google.com/maps/d/embed?mid=1u-zcxYPkzxaEUuLhEEJLbkSKTYofAwQ&ehbc=2E312F',
		latitude: '27.2046',
		longitude: '77.4977',
	},
];

export default data;
