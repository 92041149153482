import React from 'react';
import Banner from '../images/pexels-curtis-adams-8031899.jpg';
import Floorplan from './Floorplan';
import Gallery from './Gallery';
import Popup from './Popup';
import Navbar from '../common/Navbar'
import Footer from '../common/Footer';
import Contact from '../main/contact';
import i_logo from '../images/reliablehomes2.png';
import { useSelector } from 'react-redux';
import { FaDatabase } from 'react-icons/fa';

// specification images
import bedroom from './detailimages/Bed Room-01.png';
import Dining from './detailimages/dinning table-01.png';
import Kidsbedroom from './detailimages/kids bedroom 1-01.png';
import Poojaunit from './detailimages/puja unit -01.png';
import Studytable from './detailimages/study table-01.png'
import Tvunit from './detailimages/tv units-01.png';
import Wallpaint from './detailimages/wall apint final-01.png';
import Wardobe from './detailimages/foyer design 1-01.png'







const Detail = () => {
	const database = useSelector((state) => state);
const product_id=useSelector(state=>state.product_id)
const baseurl ='https://kalyanihomeadmin.kalyaniaura.com/storage/';
console.log(database)
	function ScrollToTop(){
window.scrollTo(0, 0);
			
		return null;
	}

	return (
		<div>
			<ScrollToTop />
			<Navbar />
			{database === undefined ? (
				<h1>Loading</h1>
			) : (
				<div>
					<section>
						<div className="bannerr">
							<div className="images">
								<div>
									<img src={Banner} alt="" />
								</div>
								<div>
									<img src={Banner} alt="" />
								</div>
								<div>
									<img src={Banner} alt="" />
								</div>
							</div>
							<div className="imgcont">
								<div className="i_logo">
									<img src={i_logo} alt="" />
								</div>
								<div>
									<h2>
										Kalyani {database.Products[product_id].product_name} Ready
										for you
									</h2>
									<div className="subheading">
										<p>
											Nayandalli Mysore road ,{' '}
											{database.Products[product_id].location.name} , 562125.
										</p>

										<button className="dirdot">
											<i class=" fas fa-map-marker-alt"></i>
										</button>
									</div>
									<div className="buttons">
										<div>
											<button className="b white">105 lacs onwords</button>
										</div>
										<div>
											<button
												className="b white"
												style={{ marginRight: '8px' }}
											>
												<span style={{ marginRight: '6px' }}>
													<i class="fas fa-file-download"></i>
												</span>
												Brochure
											</button>
											<button className="b ora">
												<span>
													<i className="fas fa-phone"></i>
												</span>{' '}
												{database.Products[product_id].contact_num}
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>

					<section>
						<div className="split">
							<div className="sec1">
								<div className="section">
									<h1>Project Highlights</h1>
									<table>
										<tr>
											<th>Company</th>
											<td>Germany</td>
										</tr>
										<tr>
											<th>Contact</th>
											<td>Maria Anders</td>
										</tr>
										<tr>
											<th>Contact</th>
											<td>Francisco Chang</td>
										</tr>
										<tr>
											<th>Contact</th>
											<td>Francisco Chang</td>
										</tr>
									</table>
								</div>
								<div className="section">
									<h1>About Kalyani homes at Nayandahalli</h1>
									<p>
										Kalyani Homes is the most developing Real Estate business in
										Bangalore.
									</p>
									<p>
										Kalyani Homes at Nayandahalli consist of spacious apartments
										with rental options. Luxurious apartments with parking
										facility, a swimming pool, kids play area and is pet
										friendly.{' '}
									</p>
									<p>
										The total area of apartment is around 12 to 14 acres.
										Kalyani Home at Nayandahalli is 5 minutes away from the
										metro station and has educational centre's in the radius of
										5kms.
									</p>
									<p>
										Apartments available at Nayandahalli can be customized
										according to the customer's ideas.
									</p>
								</div>
								<div className="section">
									<h1>Specifications</h1>
									<div className="secification_cards">
										<div className="s_card">
											<div className="spe_card">
												<img src={Wardobe} alt="" />
												<h2>Storage & Wardrobe</h2>
											</div>
										</div>
										<div className="s_card">
											<div className="spe_card">
												<img src={i_logo} alt="" />
												<h2>Living Room</h2>
											</div>
										</div>
										<div className="s_card">
											<div className="spe_card">
												<img src={i_logo} alt="" />
												<h2>Kitchen</h2>
											</div>
										</div>
										<div className="s_card">
											<div className="spe_card">
												<img src={Studytable} alt="" />
												<h2>Study Table</h2>
											</div>
										</div>
										<div className="s_card">
											<div className="spe_card">
												<img src={bedroom} alt="" />
												<h2>Bedroom</h2>
											</div>
										</div>
										<div className="s_card">
											<div className="spe_card">
												<img src={Dining} alt="" />
												<h2>Dining Room</h2>
											</div>
										</div>
										<div className="s_card">
											<div className="spe_card">
												<img src={Tvunit} alt="" />
												<h2>Tv Units</h2>
											</div>
										</div>
										<div className="s_card">
											<div className="spe_card">
												<img src={Kidsbedroom} alt="" />
												<h2>Kids Bedroom</h2>
											</div>
										</div>
										<div className="s_card">
											<div className="spe_card">
												<img src={Wallpaint} alt="" />
												<h2>Wall Paint</h2>
											</div>
										</div>
										<div className="s_card">
											<div className="spe_card">
												<img src={Poojaunit} alt="" />
												<h2>Pooja Unit</h2>
											</div>
										</div>
									</div>

									{/* <ol>
										<li>1. Structure</li>
										<li>2. Lobby</li>
										<li>3. Lifts</li>
										<li>4. Apartment Flooring</li>
										<li>5. Kitchen</li>
										<li>6. Toilets</li>
										<li>7.Internal doors</li>
										<li>8. External Doors</li>
										<li>9. Painting</li>
										<li>10. Electrical</li>
										<li>11. Security system</li>
										<li>12. DG Power</li>
									</ol> */}
								</div>
								<div className="section">
									<h1>Connection And Locality</h1>
									<ul>
										<li>
											JP Nagar is officially established as posh upmarket area
											in South Bangalore.
										</li>
										<li>
											Located inbetween Bennerghatta Road and Kanakapura Road.
										</li>
										<li>
											JP Nagar is a metro station on the Green Line of Namma
											Metro.
										</li>
										<li>
											Birdwatching by the the lake side - Putenahalli Lake.
										</li>
										<li>
											Area's that surround JP Nagar - Electronic City, Madiwala,
											Koramangala and BTM Layout.
										</li>
										<li>
											In close proximity to IT and Commercial Hub's such as
											Kalyani Magnum, Manyata Business Park and ITPL.
										</li>
										<li>
											Schools, Colleges, Shopping Centre, Banks, Hospitals,
											Theatres are in and around the locality.
										</li>
									</ul>
								</div>
								<Floorplan />

								<div className="section">
									<h1>Amentities</h1>
									<div className="am">
										{database.Products[product_id].amentities.map((item) => {
											return (
												<div>
													<img src={baseurl + item.image} alt="" />
													<span>{item.name}</span>
												</div>
											);
										})}
									</div>
								</div>
							</div>
							<div className="ccontact">
								{' '}
								<div className="conttt">
									{' '}
									<Contact />
								</div>
							</div>
						</div>
					</section>
					<Gallery />
				</div>
			)}
			<div className="fsection">
				<Footer />
			</div>
		</div>
	);
};

export default Detail;
