const subdata = [
	{
		id: '01',
		title: 'Hebbal',
		para: 'Emaar4 started its business operations in India business operations in India ',
		src: 'https://super.homelane.com/products/07dec18/entertainment_unit/6.jpg',
	},
	{
		id: '02',
		title: 'Rajankuntte',
		para: 'Emaar4 started its business operations in India business operations in India ',
		src: 'https://super.homelane.com/products/07dec18/entertainment_unit/7.jpg',
	},
	{
		id: '03',
		title: 'Mysore Road',
		para: 'Emaar4 started its business operations in India business operations in India ',
		src: 'https://super.homelane.com/products/07dec18/entertainment_unit/8.jpg',
	},
	{
		id: '04',
		title: 'RR Nagar',
		para: 'Emaar4 started its business operations in India business operations in India ',
		src: 'https://super.homelane.com/products/07dec18/entertainment_unit/9.jpg',
	},
	{
		id: '05',
		title: 'Basavangudi',
		para: 'Emaar4 started its business operations in India business operations in India ',
		src: 'https://super.homelane.com/products/07dec18/entertainment_unit/10.jpg',
	},
	{
		id: '06',
		title: 'Hosakerehalli',
		para: 'Emaar4 started its business operations in India business operations in India ',
		src: 'https://super.homelane.com/products/07dec18/entertainment_unit/11.jpg',
	},
	{
		id: '07',
		title: 'Nagadevanahalli',
		para: 'Emaar4 started its business operations in India business operations in India',
		src: 'https://super.homelane.com/products/07dec18/entertainment_unit/12.jpg',
	},
	{
		id: '08',
		title: 'Ullal',
		para: 'Emaar4 started its business operations in India  business operations in India',
		src: 'https://super.homelane.com/products/07dec18/entertainment_unit/13.jpg',
	},
];

export default subdata;
