// https://www.npmjs.com/package/react-responsive-carousel
import React from 'react';
import { useEffect, useState } from 'react';
import axios from 'axios';

var Bannercarousel = () => {
	const [banner, setBanner] = useState([]);
	const [num, setNum] = useState(0);
	const length = +banner.length - 1;

	const baseurl = 'https://kalyanihomeadmin.kalyaniaura.com/storage/';

	useEffect(() => {
		axios({
			method: 'get',
			url: 'https://kalyanihomeapi.kalyaniaura.com/api/banner',
			responseType: 'text/json',
		}).then(function (response) {
			setBanner(response.data);
			console.log(response.data);
		});
	}, []);

	const carousel = setInterval(() => {
		if (num === length) {
			setNum(0);
		} else {
			setNum(+num + 1);
		}
		clearInterval(carousel);
	}, 4000);

	return (
		<div className="mbanner">
			<div className="carousel">
				{banner.map((value, i) => {
					return (
						<div
							className={num === i ? 'carousel-item' : 'carousel-item inactive'}
						>
							<img src={baseurl + value.image} alt="" />
							<div className="con">
								<h2>{value.name}</h2>
								<p>{value.description}</p>
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};
export default Bannercarousel;
